import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideShownSimpleFormDirective } from './hide-shown-simple-form.directive';

@NgModule({
  declarations: [HideShownSimpleFormDirective],
  imports: [CommonModule],
  exports: [HideShownSimpleFormDirective],
})
export class HideShownSimpleFormModule {}
