import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveSearchesComponent } from './save-searches.component';

@NgModule({
  declarations: [SaveSearchesComponent],
  exports: [SaveSearchesComponent],
  imports: [CommonModule],
})
export class SaveSearchesModule {}
