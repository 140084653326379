<section>
  <article [formGroup]="form">
    <h5>{{ 'signin.forgot_password_title' | translate}}</h5>
    <input type="text" formControlName="email" placeholder="{{ 'signin.forgot_password_placeholder' | translate}}">
    <div
      *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)"
      class="error"
    >
      <div *ngIf="form.controls.email.errors.email">
        Email is not valid
      </div>
    </div>
    <button [disabled]="form.invalid" (click)="submit()">
      {{ 'signin.forgot_password_submit' | translate}}
    </button>
  </article>
</section>
