export enum PropertyHomeTypeEnum {
  HOUSE = '2',
  CONDO = '1',
  TOWN_HOUSE = '5',
  // MULTI_FAMILY = '6',
  SMALL_HOUSE = '4',
  LAND = '7',
  COMMERCIAL = '3',
  MANUFACTURED = '8',
  OBJECT = '9',
}
