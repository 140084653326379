import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { INameValue } from '@interfaces';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true,
    },
  ],
})
export class SearchSelectComponent implements ControlValueAccessor {
  @Input() options: INameValue[];
  @Input() prefix: string = '';
  @Input() addToBody: boolean = false;
  @Input() placeholder: string = '';
  @Input() useNumberFormatting: boolean = true;
  @Output() optionsOpened: EventEmitter<void> = new EventEmitter<void>();
  selectedValue: string;
  customValue: string;
  showOptions = false;

  onChange: Function;
  onTouched: Function;
  private inputValue: string;

  @ViewChild('input') inputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('optionsList') optionsListRef!: ElementRef<HTMLInputElement>;
  private inputChange = new Subject<string>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.inputChange.pipe(debounceTime(500)).subscribe((val) => {
      this.value = val;
    });
  }

  get value() {
    return this.removeFormatting(this.inputValue);
  }

  set value(value: string) {
    const fValue = this.formatString(value);
    this.inputValue = value;
    this.customValue = this.useNumberFormatting ? fValue : value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  formatString(input: string): string {
    // Remove non-numeric characters
    if (!input) return '';

    const numericOnly = input.replace(/\D/g, '');

    // Split numbers with comma after every 3 digits, starting from the end
    const reversedString = numericOnly.split('').reverse().join('');
    const formattedString = reversedString.replace(/(\d{3})(?=\d)/g, '$1,');

    return formattedString.split('').reverse().join('');
  }

  removeFormatting(input: string): string {
    return input.replace(/,/g, '');
  }

  onSelectionChange(event: any, option: INameValue) {
    this.selectedValue = option.value;
    this.customValue = option.value;
    this.closeOptionsList();
    this.inputRef.nativeElement.value = this.customValue;
    this.inputRef.nativeElement.dispatchEvent(new Event('blur'));
    event.stopPropagation();
  }

  onCustomValueBlur() {
    this.selectedValue = this.customValue;
    this.value = this.selectedValue;
  }

  onCustomValueInput(event: any) {
    const fValue = this.formatString(event.target.value);
    const newValue = event.target.value;
    this.customValue = this.useNumberFormatting ? fValue : event.target.value;
    this.inputChange.next(newValue);
  }

  onContainerClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.showOptions) {
      this.closeOptionsList();
    } else {
      this.openOptionsList();
      this.optionsOpened.emit();
      this.inputRef.nativeElement.focus();
    }
  }

  closeOptionsList(): void {
    this.showOptions = false;
  }

  openOptionsList(): void {
    if (!this.addToBody) {
      this.showOptions = true;
      return;
    }
    // Looks little dirty, but no need to handle in more ugly way
    document
      .querySelectorAll('.my-select-container > .select-icon.is-open')
      .forEach((elem: HTMLElement) => elem.click());

    this.showOptions = true;

    setTimeout(() => {
      const optionsMaxHeight = 200;
      const dropdownPosition = this.elementRef.nativeElement.getBoundingClientRect();
      const optionsList = this.elementRef.nativeElement.querySelector('.options-list');

      const openBellow = dropdownPosition.top + dropdownPosition.height + optionsMaxHeight <= window.innerHeight;

      // Move the options list to the end of the body
      this.renderer.appendChild(document.body, optionsList);

      // Position the options list below the dropdown
      this.renderer.setStyle(optionsList, 'position', 'absolute');
      this.renderer.setStyle(optionsList, 'left', `${dropdownPosition.left}px`);
      this.renderer.setStyle(optionsList, 'width', `${dropdownPosition.width}px`);
      this.renderer.setStyle(optionsList, 'height', `${optionsMaxHeight}px`);
      this.renderer.setStyle(optionsList, 'overflow-x', `auto`);
      if (openBellow) {
        this.renderer.setStyle(optionsList, 'top', `${dropdownPosition.top + dropdownPosition.height}px`);
      } else {
        this.renderer.setStyle(optionsList, 'top', `${dropdownPosition.top - optionsMaxHeight}px`);
      }
    });
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }
}
