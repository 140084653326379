// Функция для рассчета координат углов прямоугольника с заданным радиусом в километрах от исходной точки
export function calculateBoundingBox(lat: number, lon: number, radiusInKm: number) {
  // Средний радиус Земли в километрах (приближенное значение для широты около 40°)
  const earthRadius = 6371.01;

  // Радиус в радианах
  const radiusInRadians = radiusInKm / earthRadius;

  return {
    botLat: lat - radiusInRadians * (180 / Math.PI),
    botLong: lon + (radiusInRadians * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180),
    topLat: lat + radiusInRadians * (180 / Math.PI),
    topLong: lon - (radiusInRadians * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180),
  };
}

export function getRadiusByMapZoom(zoom: number): number {
  const baseZoom = 15;
  const baseRadius = 1; // 1 km for zoom level 15

  // Calculate the radius using an exponential scale
  return baseRadius * Math.pow(2, baseZoom - zoom);
}
