/**
 * Adjusts and returns a range based on the provided values.
 *
 * This function filters and adjusts the `valFrom` and `valTo` values based on their positions in the
 * `dictionary`, ensuring that the range settings are correctly configured.
 *
 * @param {number} valFrom - The starting value of the range.
 * @param {number} valTo - The ending value of the range.
 * @param {number} [step=1] - The step value to adjust `valTo` when `valFrom` equals `valTo`.
 * @returns {any} The adjusted range values `[valFrom, valTo]`.
 */
export function dependSettingsOfRange(valFrom: number, valTo: number, step: number = 1): any {
  if (valFrom) {
    this.range[0] = detectIndexesRangeByValueUti(valFrom, this.dictionary.length - 1, this.dictionary)[0];
  }

  if (valTo) {
    this.range[1] = detectIndexesRangeByValueUti(0, valTo, this.dictionary)[1];
  }

  if (valFrom === valTo) {
    const temp = detectIndexesRangeByValueUti(0, valTo, this.dictionary)[1];
    valTo = this.dictionary[temp + step];
  }

  if (valFrom && valTo && valFrom > valTo) {
    valTo = 0;
  }

  return [valFrom, valTo];
}

/**
 * Finds and returns the indexes of the provided `from` and `to` values within the `list`.
 *
 * @param {number} from - The value representing the start of the range.
 * @param {number} to - The value representing the end of the range.
 * @param {number[]} list - The list of numbers to search within.
 * @returns {number[]} An array containing the indexes `[fromIndex, toIndex]` in the `list`.
 */
function detectIndexesRangeByValueUti(from: number, to: number, list: number[]): number[] {
  const fromIndex = list.indexOf(from);
  const toIndex = list.indexOf(to);
  return [fromIndex, toIndex];
}
