import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiDataResponse, IPropertyDetailsModel } from '@interfaces';
import { Observable } from 'rxjs';
import { PROPERTY } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class PropertyDetailsService {
  constructor(private http: HttpClient) {}

  public getProperty(id: string): Observable<IApiDataResponse<IPropertyDetailsModel>> {
    return this.http.get<IApiDataResponse<IPropertyDetailsModel>>(`${PROPERTY}/${id}`);
  }

  public getSimilarProperties(id: string): Observable<IApiDataResponse<IPropertyDetailsModel[]>> {
    return this.http.get<IApiDataResponse<IPropertyDetailsModel[]>>(`${PROPERTY}/${id}/similar`);
  }
}
