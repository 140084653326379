<div class="img {{ size }}" *ngIf="!hidePhoto; else guest">
  <img
    imageError
    src="{{ checkUrlPhoto(photos) }}"
    alt="See details"
    (load)="loadStatus(false)"
    [class.blur]="loadingImage" />
  <div class="preloader" [hidden]="!loadingImage">
    <div class="sp sp-circle"></div>
  </div>
  <ng-container *ngIf="photos && photos.length > 1 && !loadingImage">
    <button class="prev" (click)="prevSlidePhoto()">
      <span></span>
    </button>
    <button class="next" (click)="nextSlidePhoto()">
      <span></span>
    </button>
  </ng-container>
</div>
<ng-template #guest>
  <div class="img {{ size }}">
    <div class="guest-block">
      <span></span>
      <p [ngClass]="size === 'medium-size' || size === 'for-map' ? 'medium-size-text' : ''">
        Due to local restrictions, please log in to see sold price and other property details.
      </p>
    </div>
    <img imageError src="{{ checkUrlPhoto(photos) }}" alt="See details" (load)="loadStatus(false)" class="blur" />
  </div>
</ng-template>
