export function removeNonUniquePartInsideAddressUtil(fullAddress: string): string {
  if (!fullAddress) {
    return '';
  }

  const replacedFullAddress = fullAddress.split(', ');
  const filteredFullAddress = [];
  replacedFullAddress.forEach((partAddress: string) => {
    if (!filteredFullAddress.includes(partAddress)) {
      filteredFullAddress.push(partAddress);
    }
  });

  return filteredFullAddress.join(', ');
}
