import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ForgotPasswordModule } from '../forgot-password/forgot-password.module';
import { SignInComponent } from './sign-in.component';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SignInComponent],
  exports: [SignInComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    ForgotPasswordModule,
    GoogleSigninButtonModule,
    TranslateModule,
  ],
})
export class SignInModule {}
