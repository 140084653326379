import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleMapComponent } from './simple-map.component';

@NgModule({
  declarations: [SimpleMapComponent],
  imports: [CommonModule],
  exports: [SimpleMapComponent],
})
export class SimpleMapModule {}
