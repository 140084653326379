import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LanguagesEnum, LocalStorageEnum } from '@enums';
import { DEFAULT_LANG } from '@helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-select',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './language-select.component.html',
  styleUrl: './language-select.component.scss',
})
export class LanguageSelectComponent implements OnInit {
  @Input() mode: 'mobile' | 'desktop' = 'desktop';
  @Input() isLoggedInUser: boolean = false;

  public lngList = Object.keys(LanguagesEnum).map((lng) => ({
    name: this.translate.instant(`enums.languages.${lng}`),
    value: lng,
    selected: false,
    imagePath: `./assets/image/flags/${lng}.svg`,
  }));
  public selectedLanguage = { ...this.lngList[0] };

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    const currentLanguage = localStorage.getItem(LocalStorageEnum.CURRENT_LANGUAGE) ?? DEFAULT_LANG;
    this.lngList.forEach((lng) => {
      if (lng.value === currentLanguage) {
        this.selectedLanguage = { ...lng };
        lng.selected = true;
      } else {
        lng.selected = false;
      }
    });
  }

  public selectLanguage(newLang): void {
    localStorage.setItem(LocalStorageEnum.CURRENT_LANGUAGE, newLang.value);
    // TODO: Uncomments after implementation on API
    // if (this.isLoggedInUser) {
    //   this.profileService
    //     .updateProfile({language: newLang.value})
    //     .pipe(takeUntilDestroyed(this.destroyRef))
    //     .subscribe((_) => {
    //       // without reload some texts are not translated
    //       // this is caused by setting up those texts in *.ts files
    //       window.location.reload();
    //     });
    // }

    // TODO: remove after implementation on API
    // without reload some texts are not translated
    // this is caused by setting up those texts in *.ts files
    window.location.reload();
  }

  public selectedClick(e: any): void {
    e.preventDefault();
    e.stopPropagation();
  }
}
