import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyString',
})
export class MoneyStringPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1000000) {
      const millions = (value / 1000000).toFixed(2);
      const roundedMillions = Math.floor(value / 1000000);
      const decimalPart = millions.substring(millions.length - 2);
      return decimalPart === '00' ? `${roundedMillions}M` : `${millions}M`;
    } else {
      return value?.toLocaleString('en-US');
    }
  }
}
