import { IPropertySearchListResponse } from '@interfaces';
import { Store } from '@ngrx/store';
import { SavedHomesService, SearchService } from '@service';
import * as SavedHomesActions from '@store';
import { takeUntil } from 'rxjs/operators';

export function loadSavedHomes(
  savedHomesService: SavedHomesService,
  searchService: SearchService,
  destroy: any,
  store: Store,
) {
  savedHomesService
    .get()
    .pipe(takeUntil(destroy))
    .subscribe((res: IPropertySearchListResponse) => {
      const data = res?.data?.length ? searchService.updatePropertyListResponse(res).data : [];
      store.dispatch(SavedHomesActions.loadSavedHomes({ data }));
    });
}
