import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NumbersOnlyInputDirective } from './numbers-only-input.directive';

@NgModule({
  declarations: [NumbersOnlyInputDirective],
  imports: [CommonModule],
  exports: [NumbersOnlyInputDirective],
})
export class NumbersOnlyInputModule {}
