import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalEnum } from '@enums';
import { environment } from '@env';
import { IPropertyOwn, IPropertySearchOwnResponse } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { injectDestroyService, provideDestroyService, SearchService, YourHomesService } from '@service';
import { ToastrService } from 'ngx-toastr';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-own-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
  providers: [provideDestroyService()],
})
export class PropertyListComponent implements OnInit {
  protected readonly ModalEnum = ModalEnum;
  private readonly destroy$ = injectDestroyService();

  public performSearch$ = new Subject<void>();

  public environment = environment;
  public pending = false;
  public pageNo = 1;
  public totalCount = 0;
  public pageSize = 16;
  public properties: IPropertyOwn[] = [];
  public formFilters: FormGroup;

  constructor(
    private router: Router,
    private yourHomesService: YourHomesService,
    private searchService: SearchService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private fb: FormBuilder,
  ) {
    this.performSearch$
      .pipe(
        switchMap(() => this.searchOwnProperties()),
        takeUntil(this.destroy$),
      )
      .subscribe((response: IPropertySearchOwnResponse) => {
        response.data?.forEach((p: any) => {
          this.searchService.setPropertyCardInfoLine(p);
          this.searchService.setPropertyCardAdditionalInfoLine(p);
        });

        this.properties = response.data;
        this.totalCount = response.totalCount;
        this.pending = false;
      });
  }

  public ngOnInit(): void {
    this.formFilters = this.fb.group({
      onlyActive: [false],
      rentProperty: [true],
      saleProperty: [true],
    });

    this.formFilters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.performSearch$.next();
    });

    this.performSearch$.next();
  }

  public searchOwnProperties() {
    this.pending = true;
    const body = {
      page: this.pageNo,
      size: this.pageSize,
      onlyActive: !!this.formFilters.value.onlyActive ?? false,
      rentProperty: !!this.formFilters.value.rentProperty ?? false,
      saleProperty: !!this.formFilters.value.saleProperty ?? false,
    };

    return this.yourHomesService.searchProperties(body).pipe(
      switchMap((res: HttpResponse<IPropertySearchOwnResponse>) => {
        const response: IPropertySearchOwnResponse =
          res.status === 204
            ? { count: 0, totalCount: 0, data: [] }
            : this.yourHomesService.updatePropertyOwnResponse(res.body);
        return [response];
      }),
    );
  }

  public onPageChanged(page: number): void {
    this.pageNo = page;
    this.performSearch$.next();
  }

  public propertyActivation(activityStatus: boolean, property: IPropertyOwn): void {
    const apiCall = activityStatus
      ? this.yourHomesService.activateProperty(property.id)
      : this.yourHomesService.deactivateProperty(property.id);

    const i = this.properties.findIndex((p) => p.id === property.id);
    this.properties[i] = { ...this.properties[i], isActiveStatus: activityStatus };

    apiCall.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      const msgVar = activityStatus ? 'own_properties.property_activated' : 'own_properties.property_deactivated';
      this.toastr.success(this.translate.instant(msgVar));
    });
  }

  public editProperty(property: IPropertyOwn): void {
    this.router.navigate([`myzex/your-homes/details/${property.id}`]);
  }
}
