import { Component, OnInit } from '@angular/core';
import { PropertySortEnum } from '@enums';
import { INameValue } from '@interfaces';
import * as FilterActions from '../../../store/actions/search-page.actions';
import { select, Store } from '@ngrx/store';
import { AppState, selectSortOrder } from '@store';
import { injectDestroyService, provideDestroyService } from '@service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sort-order',
  templateUrl: './sort-order.component.html',
  styleUrls: ['./sort-order.component.scss'],
  providers: [provideDestroyService()],
})
export class SortOrderComponent implements OnInit {
  private readonly destroy$ = injectDestroyService();

  public optionsList: INameValue[] = [];
  public sortOrder: INameValue | null;
  public showOptions = false;

  constructor(
    public store: Store<AppState>,
    private translate: TranslateService,
  ) {
    this.optionsList = [
      { value: `${PropertySortEnum.Newest}`, name: this.translate.instant('enums.property_sort.Newest') },
      { value: `${PropertySortEnum.PriceAsc}`, name: this.translate.instant('enums.property_sort.PriceAsc') },
      { value: `${PropertySortEnum.PriceDesc}`, name: this.translate.instant('enums.property_sort.PriceDesc') },
      { value: `${PropertySortEnum.Bedrooms}`, name: this.translate.instant('enums.property_sort.Bedrooms') },
      { value: `${PropertySortEnum.Bathrooms}`, name: this.translate.instant('enums.property_sort.Bathrooms') },
      { value: `${PropertySortEnum.HomeSize}`, name: this.translate.instant('enums.property_sort.HomeSize') },
      { value: `${PropertySortEnum.LotSize}`, name: this.translate.instant('enums.property_sort.LotSize') },
    ];
  }

  public ngOnInit(): void {
    this.store
      .pipe(select(selectSortOrder), takeUntil(this.destroy$))
      .subscribe((sort: PropertySortEnum) => (this.sortOrder = this.optionsList.find((item) => item.value === sort)));
  }

  public sortChanged(option: INameValue): void {
    this.store.dispatch(FilterActions.sortOrderChanged({ data: option.value as PropertySortEnum }));
  }

  public handleOpenState(value?: boolean): void {
    this.showOptions = value === undefined ? !this.showOptions : value;
  }
}
