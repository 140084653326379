<section
  class="properties-wrapper"
  [class.opacity-50]="pending"
  [class.no-paging]="totalCount <= pageSize"
>
  <div class="filters" [formGroup]="formFilters" *ngIf="!pending">
    <label>
      <input type="checkbox" formControlName="onlyActive"> <span>{{ 'own_properties.only_active' | translate }}</span>
    </label>
    <label>
      <input type="checkbox" formControlName="rentProperty">
      <span>{{ 'own_properties.rent' | translate }}</span>
    </label>
    <label>
      <input type="checkbox" formControlName="saleProperty"> <span>{{ 'own_properties.sale' | translate }}</span>
    </label>
  </div>

  <ng-container *ngFor="let item of properties; let i = index;">
    <app-property-default-card
      [property]="item"
      [buttonFunction]="'activation'"
      [mode]="'your-homes'"
      (propertyActivation)="propertyActivation($event, item)"
      (editHomeDetails)="editProperty(item)"
    >
    </app-property-default-card>
  </ng-container>
  <app-paging
    *ngIf="totalCount > pageSize"
    [class.opacity-50]="pending"
    [pageSize]="pageSize"
    [currentPage]="pageNo"
    [totalElements]="totalCount"
    (pageChanged)="onPageChanged($event)"
  >
  </app-paging>
</section>

<div *ngIf="pending" class="preloader">
  <div class="sp sp-circle"></div>
</div>
