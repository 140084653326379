import { ICoordinatesRect } from '@interfaces';
import { IPolygon } from '../interfaces/polygon.interface';

export function converterCoordinatesPolygon(coordinates: ICoordinatesRect) {
  return {
    botLat: `${coordinates.botLat}`,
    botLong: `${coordinates.botLong}`,
    topLat: `${coordinates.topLat}`,
    topLong: `${coordinates.topLong}`,
  };
}

export function converterCoordinatesRect(coordinates: IPolygon) {
  return {
    botLat: +coordinates.botLat,
    botLong: +coordinates.botLong,
    topLat: +coordinates.topLat,
    topLong: +coordinates.topLong,
  };
}
