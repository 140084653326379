import { ComponentRef, ViewChild, ViewContainerRef, Directive } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TYPE_PROPERTY } from './constants';
import { PropertyDetailsComponent } from '../modules/property-details/property-details.component';

// TODO: should be removed

@Directive()
export class PropertyDetailsUtils {
  @ViewChild('propertyDetails', { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>;
  hideSearchContent = false;
  constructor(
    public location: Location,
    public router: Router,
  ) {}

  seeDetailsModal(event: any, viewData: any, isLoggedUser?: boolean): void {
    if (!isLoggedUser && viewData.listingAvailabilityType === TYPE_PROPERTY.UNAVAILABLE) {
      this.container.clear();
      // this.componentRef = this.container.createComponent(SignUpComponentModal);
      this.componentRef.instance.closeModal.subscribe((_) => {
        this.container.clear();
      });
      return;
    }

    if (event.tagName !== 'IMG' && event.tagName !== 'P' && event.className !== 'details') {
      return;
    }

    let addressFormatted;

    if (viewData.addressListing) {
      addressFormatted = viewData.addressListing.replace(/\s+/g, '-').toLowerCase();
    }

    if (viewData.address) {
      addressFormatted = viewData.address.streetFull.replace(/\s+/g, '-').toLowerCase();
    }

    const regionNameFormatted = viewData.regionName.replace(/\s+/g, '-').toLowerCase();
    const path = `/property/${viewData.id}/${viewData.roomTotal}-rooms-${addressFormatted}-${regionNameFormatted}`;

    this.location.replaceState(path);

    this.container.clear();
    this.componentRef = this.container.createComponent(PropertyDetailsComponent);
    this.componentRef.instance.idPropertyFromSearchPage = viewData.id;
    this.componentRef.instance.page = this.router.url;
    this.componentRef.instance.closeModal.subscribe((_) => {
      this.hideSearchContent = false;
      this.container.clear();
    });

    this.hideSearchContent = true;
  }

  closeModalDetails() {
    this.location.replaceState(this.router.url);
    this.hideSearchContent = false;
    this.container.clear();
  }
}
