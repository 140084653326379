<div style="border: 1px solid #ccc">
  <app-slider
    size="for-map"
    [isDemoPhoto]="marker.isDemoPhoto"
    [photos]="marker.images"
    [hidePhoto]="false"
    (click)="seeDetails($event)"
  ></app-slider>

  <div class="info">
    <p class="price">
      {{ marker.price | currency:  marker.priceCurrency :true:'1.0-0' }}
    </p>
    <div class="rooms">
      <span class="bedrooms">{{ marker.bedroom }}</span>
      <span class="bathrooms">{{ marker.bathroom }}</span>
    </div>
  </div>
</div>
