import { Component, forwardRef, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputFormComponent implements ControlValueAccessor {
  @Input() label;
  @Input() type = 'text';
  @Input() autofocus = false;
  @Input() required = false;
  @Input() readonly = false;
  @Input() borderColor: any = false;
  @Input() errorText;
  @Input() externalValue: any;

  onChange: Function;
  onTouched: Function;
  private inputValue: string;

  get value() {
    return this.inputValue;
  }

  set value(value: string) {
    this.inputValue = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  @HostListener('click') click() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  change(data) {
    this.value = data.target.value;
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }
}
