import { FilterNameEnum } from '@enums';
import { ISearchFilter } from '@interfaces';

export function propertyValuesToString(filter: ISearchFilter): Partial<ISearchFilter> {
  const numberFields = [
    FilterNameEnum.bathroomNumber,
    FilterNameEnum.bedroomNumber,
    FilterNameEnum.homeSizeFrom,
    FilterNameEnum.homeSizeTo,
    FilterNameEnum.lotSizeFrom,
    FilterNameEnum.lotSizeTo,
    FilterNameEnum.parkingNumber,
    FilterNameEnum.priceFrom,
    FilterNameEnum.priceTo,
    FilterNameEnum.propertyType,
    FilterNameEnum.yearBuildFrom,
    FilterNameEnum.yearBuildTo,
  ];
  const arrayFields = [FilterNameEnum.condition, FilterNameEnum.homeType];

  const result = {};

  numberFields.forEach((f) => {
    result[f] =
      filter[f] > 0
        ? filter[f].toString()
        : f === FilterNameEnum.bedroomNumber || f === FilterNameEnum.bathroomNumber || FilterNameEnum.parkingNumber
          ? '0'
          : undefined;
  });

  arrayFields.forEach(
    (f) => (result[f] = !!filter[f] && filter[f]?.length ? filter[f]?.map((i) => i.toString()) : undefined),
  );

  return result;
}
