import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pseudoZoom]',
})
export class PseudoZoomDirective {
  private zoomed = false;
  private originalStyles: any;

  @Input() pseudoZoom: string = '100%';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('click') onClick() {
    if (!this.zoomed) {
      this.originalStyles = {
        position: this.el.nativeElement.style.position,
        zIndex: this.el.nativeElement.style.zIndex,
        top: this.el.nativeElement.style.top,
        left: this.el.nativeElement.style.left,
        width: this.el.nativeElement.style.width,
        height: this.el.nativeElement.style.height,
      };

      const blackOverlay = this.renderer.createElement('div');
      this.renderer.addClass(blackOverlay, 'zoom-overlay');
      this.renderer.setStyle(blackOverlay, 'position', 'fixed');
      this.renderer.setStyle(blackOverlay, 'top', '0');
      this.renderer.setStyle(blackOverlay, 'left', '0');
      this.renderer.setStyle(blackOverlay, 'right', '0');
      this.renderer.setStyle(blackOverlay, 'bottom', '0');
      this.renderer.setStyle(blackOverlay, 'background-color', 'black');
      this.renderer.setStyle(blackOverlay, 'z-index', '9998');

      const closeButton = this.renderer.createElement('div');
      this.renderer.addClass(closeButton, 'close-button');
      closeButton.innerHTML = 'X';
      this.renderer.setStyle(closeButton, 'position', 'absolute');
      this.renderer.setStyle(closeButton, 'width', '20px');
      this.renderer.setStyle(closeButton, 'height', '20px');
      this.renderer.setStyle(closeButton, 'z-index', '9999');
      this.renderer.setStyle(closeButton, 'top', '10px');
      this.renderer.setStyle(closeButton, 'right', '10px');
      this.renderer.setStyle(closeButton, 'color', 'white');
      this.renderer.setStyle(closeButton, 'cursor', 'pointer');
      this.renderer.listen(closeButton, 'click', () => this.closeZoom());

      this.renderer.appendChild(document.body, blackOverlay);
      this.renderer.appendChild(document.body, closeButton);

      this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.el.nativeElement, 'z-index', '9999');
      this.renderer.setStyle(this.el.nativeElement, 'top', '10%');
      this.renderer.setStyle(this.el.nativeElement, 'left', '10%');
      this.renderer.setStyle(this.el.nativeElement, 'width', '80%');
      this.renderer.setStyle(this.el.nativeElement, 'height', '80%');

      this.zoomed = true;
    }
  }

  private closeZoom() {
    this.renderer.removeChild(document.body, document.querySelector('.zoom-overlay'));
    this.renderer.removeChild(document.body, document.querySelector('.close-button'));

    this.renderer.setStyle(this.el.nativeElement, 'position', this.originalStyles.position);
    this.renderer.setStyle(this.el.nativeElement, 'z-index', this.originalStyles.zIndex);
    this.renderer.setStyle(this.el.nativeElement, 'top', this.originalStyles.top);
    this.renderer.setStyle(this.el.nativeElement, 'left', this.originalStyles.left);
    this.renderer.setStyle(this.el.nativeElement, 'width', this.originalStyles.width);
    this.renderer.setStyle(this.el.nativeElement, 'height', this.originalStyles.height);

    this.zoomed = false;
  }
}
