<footer [hidden]="hidden">
  <section class="footer-content">
<!--    <ul>-->
<!--      <li *ngFor="let item of helpMenu" routerLink="{{ item.path }}">{{ item.name }} </li>-->
<!--    </ul>-->

<!--    <ul>-->
<!--      <li *ngFor="let item of serviceMenu" routerLink="{{ item.path }}">{{ item.name }} </li>-->
<!--    </ul>-->
    <p class="copyright">{{'footer.copyright' | translate: {'%currentYear': currentYear} }}</p>
  </section>
</footer>
