import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchHighlightDirective } from './search-highlight.directive';

@NgModule({
  declarations: [SearchHighlightDirective],
  imports: [CommonModule],
  exports: [SearchHighlightDirective],
})
export class SearchHighlightModule {}
