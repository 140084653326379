import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSetTitleComponent } from './modal-set-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomElementsFormModule } from '../../custom-elements-form.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ModalSetTitleComponent],
  imports: [CommonModule, TranslateModule, CustomElementsFormModule, ReactiveFormsModule],
  exports: [ModalSetTitleComponent],
})
export class ModalSetTitleModule {}
