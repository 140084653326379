import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoneyShortStringPipe } from './money-short-string.pipe';
import { NotificationFilterPipe } from './notification-filter.pipe';
import { NotificationFilterCountPipe } from './notification-filter-count.pipe';
import { MoneyStringPipe } from './money-string.pipe';
import { NumberToShortStringPipe } from './number-short-string.pipe';

@NgModule({
  declarations: [
    NotificationFilterPipe,
    NotificationFilterCountPipe,
    MoneyStringPipe,
    MoneyShortStringPipe,
    NumberToShortStringPipe,
  ],
  imports: [CommonModule],
  providers: [MoneyStringPipe, MoneyShortStringPipe, NumberToShortStringPipe],
  exports: [MoneyStringPipe, NotificationFilterPipe, NotificationFilterCountPipe],
})
export class PipesModule {}
