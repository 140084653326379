import { Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SetStructureListModel } from '@interfaces';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectListComponent),
      multi: true,
    },
  ],
})
export class SelectListComponent implements OnInit, ControlValueAccessor {
  select = null;
  onChange: Function;
  onTouched: Function;
  @Input() objectList;
  @Input() objectName;
  @Input() setOption;
  @Input() isSimpleList = true;
  @Input() setStructureList: SetStructureListModel;
  @Output() selected = new EventEmitter();

  private selectValue: string;

  get value() {
    return this.selectValue;
  }

  set value(value: string) {
    this.selectValue = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  ngOnInit() {}

  @HostListener('click', ['$event.target']) click(event) {
    this.toggle(event);
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(event.id);
    }
    this.selected.emit(event.id);
  }

  toggle(event) {
    this.select = event.innerText;
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }
}
