export function replaceSpecialCharacters(inputString: string): string {
  const replaceDict: Record<string, string> = {
    á: 'a',
    č: 'c',
    ď: 'd',
    é: 'e',
    ě: 'e',
    í: 'i',
    ľ: 'l',
    ň: 'n',
    ó: 'o',
    ô: 'o',
    ř: 'r',
    š: 's',
    ť: 't',
    ú: 'u',
    ů: 'u',
    ý: 'y',
    ž: 'z',
  };

  let resultString: string = inputString;

  for (const key in replaceDict) {
    if (replaceDict.hasOwnProperty(key)) {
      const value = replaceDict[key];
      resultString = resultString.replace(new RegExp(key, 'g'), value);
    }
  }

  return resultString;
}
