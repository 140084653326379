export interface AppState {
  filters: FiltersStore;
  sorts: SortPropertyStore;
  map: MapStore;
  mapMarkers: MapMarkers;
  user: any;
  property: any;
  propertiesList: any;
  selectedProperty: SelectedProperty;
  openedFilter: OpenedFilter;
}

export interface SelectedProperty {
  id: any;
}

export interface OpenedFilter {
  name: any;
}

export interface MapStore {
  bounds: any;
  centerMap: any;
  currentZoom: any;
  setting: any;
}

export interface FiltersStore {}

export interface SortPropertyStore {
  selectedType: string;
  selectedSort: string;
  updated: any;
}

export interface MapMarkers {
  markers: [];
}
