import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalsService } from '@service';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
})
export class BasicModalComponent implements OnInit {
  @Input() modalId: number;
  @Input() data: any;

  public isOpen: boolean = false;

  @HostListener('click', ['$event'])
  onModalClick(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.close();
    }
  }

  constructor(private modalsService: ModalsService) {}

  ngOnInit() {
    this.modalsService.add(this);
  }

  public open() {
    this.stopScrollToggle();
    const modal = document.getElementById(this.modalId.toString());
    modal.style.visibility = 'visible';
    this.isOpen = true;
  }

  public close() {
    this.stopScrollToggle();
    const modal = document.getElementById(this.modalId.toString());
    modal.style.visibility = 'hidden';
    this.isOpen = false;
  }

  public stopScrollToggle() {
    const body = document.querySelector('body');
    body.style.overflow = body.style.overflow !== 'hidden' ? 'hidden' : 'auto';
  }
}
