import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchHighlightModule, DirectivesModule } from '@directives';
import { SearchInputFormComponent } from './search-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SearchInputFormComponent],
  imports: [
    CommonModule,
    NgbTypeaheadModule,
    FormsModule,
    ReactiveFormsModule,
    SearchHighlightModule,
    DirectivesModule,
    TranslateModule,
  ],
  exports: [SearchInputFormComponent],
})
export class SearchInputModule {}
