import { Component, OnInit } from '@angular/core';
import { SavedSearchesService } from '@service';
import { EmailFrequencyEnum } from '@enums';

@Component({
  selector: 'app-save-searches',
  templateUrl: './save-searches.component.html',
  styleUrls: ['./save-searches.component.scss'],
})
export class SaveSearchesComponent implements OnInit {
  constructor(private saveSearchesService: SavedSearchesService) {}

  ngOnInit(): void {
    this.saveSearches();
  }

  saveSearches() {
    // this.saveSearchesService.save({});
  }

  updateSaveSearches(id, variantNotify: EmailFrequencyEnum) {
    // this.saveSearchesService.updateEmailNotify(id, variantNotify);
  }
}
