import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '@enums';
import { Observable } from 'rxjs';
import { NOMINATIM } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class NominatimService {
  constructor(private http: HttpClient) {}

  public searchAddress(userValue: string, limit = 15, geojson = 1): Observable<any> {
    const params = {
      addressdetails: 1,
      dedupe: 1,
      limit,
      format: 'jsonv2',
      namedetails: 1,
    };

    const headers = {
      'Accept-Language': localStorage.getItem(LocalStorageEnum.CURRENT_LANGUAGE) || 'en',
    };

    // @ts-ignore
    return this.http.get(`${NOMINATIM}?${new URLSearchParams(params).toString()}&q=${encodeURIComponent(userValue)}`, {
      headers,
    });
  }
}
