export enum CurrencySymbolsEnum {
  EUR = '€',
  GBP = '£',
  CHF = 'CHF',
  SEK = 'kr',
  NOK = 'kr',
  DKK = 'kr',
  PLN = 'zł',
  CZK = 'Kč',
  HUF = 'Ft',
  RON = 'lei',
  BGN = 'лв',
  HRK = 'kn',
  UAH = '₴',
  TRY = '₺',
}
