import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { YourHomesComponent } from './your-homes.component';
import { YourPropertyDetailsComponent } from './property-details/your-property-details.component';

const routes: Routes = [
  { path: '', component: YourHomesComponent },
  { path: 'details/:id', component: YourPropertyDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class YourHomesRoutingModule {}
