import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-when-click-label-on-label',
  templateUrl: './modal-when-click-label-on-label.component.html',
  styleUrls: ['./modal-when-click-label-on-label.component.scss'],
})
export class ModalWhenClickLabelOnLabelComponent {
  @Input() marker;

  constructor(private router: Router) {}

  seeDetails(event) {
    if (event.target.tagName !== 'BUTTON') {
      const address = Object.values(this.marker.address)
        .join('--')
        .replace(/[^\w]/gi, '-')
        .replace('--', '-')
        .toLowerCase();
      const rooms = `${this.marker.bedroom}-rooms-`;
      this.router.navigate([`/property/${this.marker.id}/${rooms}${address}`]);
    }
  }
}
