import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSystemInfoComponent } from './modal-system-info.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalSystemInfoComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ModalSystemInfoComponent],
})
export class ModalSystemInfoModule {}
