import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesListComponent } from './images-list.component';
import { DirectivesModule } from '@directives';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ImagesListComponent],
  imports: [CommonModule, DirectivesModule, TranslateModule],
  exports: [ImagesListComponent],
})
export class ImagesListModule {}
