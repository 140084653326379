import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { getCurrentDate } from '@helpers';

@Component({
  selector: 'app-base-auth',
  templateUrl: './base-auth.component.html',
  styleUrl: './base-auth.component.scss',
})
export class BaseAuthComponent implements OnInit {
  public inputVal: string;

  constructor() {}

  ngOnInit() {
    if (!environment.useBaseAuth || sessionStorage.getItem('baseAuthDone') === getCurrentDate()) {
      window.location.reload();
    }
  }

  clickFn() {
    sessionStorage.setItem('baseAuthDone', this.inputVal ?? '');
    window.location.reload();
  }
}
