/**
 * Formats a date string in the format "DD-MM-YYYY" to a localized date string in the format "Thursday, July 28".
 * Uses the browser's locale settings for displaying month and day names.
 *
 * @param {string} inputDate - The date string to be formatted in the format "DD-MM-YYYY".
 * @returns {string} The formatted date string in the format "Thursday, July 28".
 *
 * @example
 * const inputDate = '28-07-2023';
 * const convertDateStringToHuman = convertDateStringToHuman(inputDate);
 * console.log(convertDateStringToHuman); // Output (based on browser's locale): "Thursday, July 28" or the corresponding format in another language
 */

export function convertDateStringToHuman(inputDate: string): string {
  const dateParts = inputDate.split('-');
  const day = parseInt(dateParts[0], 10);
  const monthIndex = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  const date = new Date(year, monthIndex, day);
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });

  return dateFormatter.format(date);
}
