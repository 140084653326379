<div class="variables-block {{classes}}" [class.shadow]="showShadow">
  <div class="header" *ngIf="headerText">{{ headerText }}</div>
  <div class="body">
    <div class="d-flex flex-column">
      <div class="range-slider" [class.m-1]="addToBody">
        @if (!useNumberFormatting) {
          <span>{{ getValue(range[0]) === 0 ? unselected : getValue(range[0])}}</span>
          <span>{{ getValue(range[1]) >= this.getValue(this.values.length - 1) ? unselected : getValue(range[1]) }}</span>
        } @else {
          <span>{{ getValue(range[0]) === 0 ? unselected : getValue(range[0]) | moneyString }}</span>
          <span>{{ getValue(range[1]) >= this.getValue(this.values.length - 1) ? unselected : getValue(range[1]) | moneyString }}</span>
        }
      </div>
    </div>
    <p-slider
      [(ngModel)]="range"
      [min]="0"
      [max]="values.length - 1"
      [step]="1"
      [animate]="true"
      [range]="true"
      (onSlideEnd)="onSliderChange($event)"
      styleClass="w-14rem"
    >
    </p-slider>
  </div>
  <button class="apply" *ngIf="showApplyBtn" (click)="closeAction()">{{ 'range_select.apply' | translate }}</button>
</div>