import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysOnMarketFormatComponent } from './days-on-market-format.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DaysOnMarketFormatComponent],
  imports: [CommonModule, TranslateModule],
  exports: [DaysOnMarketFormatComponent],
})
export class DaysOnMarketFormatModule {}
