import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-system-info',
  templateUrl: './modal-system-info.component.html',
  styleUrls: ['../../../../assets/styles/modals.scss', './modal-system-info.component.scss'],
})
export class ModalSystemInfoComponent implements OnInit {
  @Input() content;
  @Output() action = new EventEmitter();

  readonly STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
  };

  ngOnInit() {
    this.autoClose();
  }

  close() {
    this.action.emit(false);
  }

  autoClose() {
    if (this.content.autoClose) {
      setTimeout((_) => this.close(), 3000);
    }
  }
}
