import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private modals: any[] = [];

  add(modal: any) {
    const modalIndex = this.modals.findIndex((m) => m.modalId === modal.modalId);
    if (modalIndex >= 0) {
      this.modals.splice(modalIndex);
    }
    this.modals.push(modal);
  }

  remove(id: number) {
    this.modals = this.modals.filter((modal) => modal.modalId !== id);
  }

  open(id: number) {
    const modal = this.modals.find((modal) => modal.modalId === id);
    modal.open();
  }

  close(id: number) {
    const modal = this.modals.find((modal) => modal.modalId === id);
    modal.close();
  }
}
