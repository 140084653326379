<section class="mobileMenuContainer">
  <header>
    <div class="top">
      <a class="logo" routerLink="/" (click)="closeMobileMenu($event)">
        {{ BRAND_APP }}
        <span>app</span>
        <span class="beta">Beta</span>
      </a>
    </div>
  </header>
  <article>
    <nav class="navbar">
      <ul class="navbar-nav navbar-expand menu">
        <ng-container *ngFor="let item of headerMenu; let i = index" class="nav-item">
          <li [ngClass]="item.child ? 'dropdown' : ''">
            <a [routerLink]="item.path"
               (click)="closeMobileMenu($event); setFilter(item.params)">
              {{ item.name | translate }}
            </a>
            <div class="arrow" *ngIf="item.child" (click)="openChildMenu(i)">
              <svg width="20" height="10" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.1643 0.755019C12.9907 0.581376 12.7085 0.581376 12.5349 0.755019L7.00001 6.30075L1.45427 0.755019C1.28063 0.581376 0.998456 0.581376 0.824813 0.755019C0.651169 0.928662 0.651169 1.21083 0.824813 1.38448L6.67443 7.23409C6.76125 7.32091 6.86977 7.36432 6.98915 7.36432C7.09768 7.36432 7.21706 7.32091 7.30388 7.23409L13.1535 1.38448C13.338 1.21083 13.338 0.928662 13.1643 0.755019Z" />
              </svg>
            </div>
          </li>

          <ul *ngIf="item.child" class="child hide">
            <li *ngFor="let itemchild of item.child">
              <p *ngIf="itemchild.title">{{ itemchild.title | translate }}</p>
              <ng-container *ngFor="let blockLinks of itemchild.links">
                <a *ngFor="let link of blockLinks"
                   [routerLink]="link.path"
                   (click)="closeMobileMenu($event); setFilter(link.params)"
                >
                  {{ link.name | translate }}
                </a>
              </ng-container>
            </li>
          </ul>
        </ng-container>
        <li *ngIf="!isLoggedUser" class="nav-item" (click)="closeMobileMenu($event)">
          <a [routerLink]="addNewItem.path">
            {{ addNewItem.name }}
          </a>
        </li>
      </ul>
    </nav>
  </article>
  <a class="logout" *ngIf="isLoggedUser" (click)="logout()" rel="nofollow">{{ 'header.logout' | translate }}</a>
  <article class="mobile-language-selector">
    <app-language-select [mode]="'mobile'" [isLoggedInUser]="isLoggedUser"></app-language-select>
  </article>
</section>
