export enum LocalStorageEnum {
  DevelopTools = 'developTools',
  GeoUserInfo = 'GeoUserInfo',
  SearchFilter = 'SearchFilter',
  AUTH_TOKEN = 'AUTH_TOKEN',
  InitSearchParams = 'InitSearchParams',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  DEVICE_ID = 'DEVICE_ID',
  HIDDEN_PROPERTIES = 'HIDDEN_PROPERTIES',
  CURRENT_LANGUAGE = 'CURRENT_LANGUAGE',
  CURRENT_LANGUAGES_STATE = 'CURRENT_LANGUAGES_STATE',
}
