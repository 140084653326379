export enum LanguagesEnum {
  en = 'en',
  sk = 'sk',
  ua = 'ua',
  ru = 'ru',
  // cz = 'cz',
  // de = 'de',
  // it = 'it',
  // pl = 'pl',
}
