import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-set-title',
  templateUrl: './modal-set-title.component.html',
  styleUrls: ['./modal-set-title.component.scss'],
})
export class ModalSetTitleComponent implements OnInit {
  @Input() title;
  @Input() description;
  @Input() applyButton;
  valueInput = '';
  saveSearchForm: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.valueInput = this.activatedRoute.snapshot.queryParams.city;
  }

  ngOnInit(): void {
    this.createSaveSearchForm();
  }

  change(event) {
    this.valueInput = event.value;
  }

  createSaveSearchForm() {
    this.saveSearchForm = this.formBuilder.group({
      nameSearch: '',
    });
  }
}
