import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent, CustomRadioComponent, InputFormComponent, SelectListComponent } from './component';
import { SearchSelectComponent } from './component/search-select/search-select.component';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@directives';

@NgModule({
  declarations: [
    InputFormComponent,
    CheckboxComponent,
    SelectListComponent,
    SearchSelectComponent,
    CustomRadioComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule, DirectivesModule, NgOptimizedImage],
  exports: [InputFormComponent, CheckboxComponent, SelectListComponent, CustomRadioComponent, SearchSelectComponent],
})
export class CustomElementsFormModule {}
