import { Directive, Input, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[lessThanOrEqual]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: LessThanOrEqualValidatorDirective,
      multi: true,
    },
  ],
})
export class LessThanOrEqualValidatorDirective implements Validator, OnDestroy {
  @Input('lessThanOrEqual') otherControlName: string;
  private subscriptions: Subscription[] = [];

  constructor() {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const otherControl = control.root.get(this.otherControlName) as FormControl;

    if (!otherControl) {
      return null;
    }

    this.subscriptions.push(
      control.valueChanges.subscribe(() => {
        otherControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }),
    );

    this.subscriptions.push(
      otherControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }),
    );

    const value = control.value;
    const otherValue = otherControl.value;

    if (value !== null && otherValue !== null && value > otherValue) {
      return { lessThanOrEqual: true };
    } else {
      return null;
    }
  }
}
