import { isPlatformBrowser } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { environment } from '@env';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthEffects, headerReducer, savedHomesReducer, SearchPageEffects, searchPageReducer } from '@store';
import { FooterModule } from './common/footer/footer.module';
import { HeaderModule } from './common/header/header.module';
import { AuthInterceptors } from './interceptors/auth.interceptors';
import { DeviceIdInterceptors } from './interceptors/device-id-interceptors';
import { TokenHealthComponent } from './pages/token-health/token-health.component';
import { MobileMenuComponent } from './shared/component/mobile-menu/mobile-menu.component';
import { BasicModalModule } from './shared/modules/basic-modal/basic-modal.module';
import { SaveSearchesModule } from './shared/modules/save-searches/save-searches.module';
import { SignInModule } from './shared/modules/sign-in/sign-in.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { YourHomesModule } from './pages/cabinet/modules/your-homes/your-homes.module';
import { LocalStorageEnum } from '@enums';
import { DEFAULT_LANG } from '@helpers';
import { BaseRootComponent } from './base/base-root/base-root.component';
import { BaseAuthComponent } from './base/base-auth/base-auth.component';
import { AppRootComponent } from './base/app-root/app.component';
import { FormsModule } from '@angular/forms';

declare let require: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  hammer = null;
  overrides: any;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {
    super();
    if (isPlatformBrowser(platformId)) {
      this.hammer = require('hammerjs');
      this.overrides = <any>{
        swipe: { direction: this.hammer.DIRECTION_ALL },
      };
    }
  }
}

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerTranslateFactory(translate: TranslateService): any {
  return () => {
    const lng = localStorage.getItem(LocalStorageEnum.CURRENT_LANGUAGE) ?? DEFAULT_LANG;
    translate.setDefaultLang(DEFAULT_LANG);
    return translate.use(lng).toPromise();
  };
}

@NgModule({
  declarations: [TokenHealthComponent, AppRootComponent, BaseAuthComponent, BaseRootComponent],
  bootstrap: [BaseRootComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app' }),
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule,
    FormsModule,
    HeaderModule,
    FooterModule,
    HttpClientModule,
    StoreModule.forRoot({
      header: headerReducer,
      searchPage: searchPageReducer,
      savedHomes: savedHomesReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([AuthEffects, SearchPageEffects]),
    BasicModalModule,
    SignInModule,
    SaveSearchesModule,
    YourHomesModule,
    MobileMenuComponent,
  ],
  providers: [
    // TODO: temporary hide { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptors,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceIdInterceptors,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          // {
          //   id: GoogleLoginProvider.PROVIDER_ID,
          //   provider: new GoogleLoginProvider(environment.googleClientId),
          // },
        ],
        onError: (err) => {
          // TODO: Add some error handling
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
  ],
})
export class AppModule {}
