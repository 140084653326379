import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFullAddress]',
})
export class FullAddressDirective {
  hoverClass = 'full-address';

  constructor(private element: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.element.nativeElement.classList.add(this.hoverClass);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.element.nativeElement.classList.remove(this.hoverClass);
  }
}
