<section class="search-in-page-search">
  <div class="search-form" [formGroup]="form" (clickOutside)="closeSearch()">
    <input
      type="text"
      autocomplete="off"
      formControlName="input"
      placeholder="{{'address_search_input.placeholder' | translate}}"
      (keydown.arrowUp)="selectPreviousOption()"
      (keydown.arrowDown)="selectNextOption()"
      (keydown.enter)="selectCurrentOption()"
    />
    <button></button>
    <div class="results">
      <ng-container *ngIf="statesFull.length;else searchNotFound">
        <p *ngFor="let item of statesFull; let index = index;"
           [class.selected]="index === currentOptionIndex"
           (click)="selectAddress(item)"
        >
          <span [searchHighlight]="form.controls.input.value">{{item.view}}</span>
        </p>
      </ng-container>
    </div>
  </div>

</section>
<ng-template #searchNotFound>
  <p *ngIf="form.controls.input.value.length"> {{'address_search_input.not_found' | translate}}</p>
</ng-template>
