import { demoImages } from '../constants/property-dict';
import { environment } from '@env';

export function mapperDemoPhotosUtils(data: any) {
  return data.map((data) => {
    if (!data.images?.length && environment.useDemoPhotos) {
      const randomNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
      const demoPhotos = demoImages[randomNumber].map((img) => `${randomNumber}/${img}`);
      data = { ...data, images: demoPhotos, isDemoPhoto: true };
    }

    return data;
  });
}

export function mapperDemoPhotosUtilsSingle(data: any) {
  if (!data.images?.length && environment.useDemoPhotos) {
    const randomNumber = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    const demoPhotos = demoImages[randomNumber].map((img) => `${randomNumber}/${img}`);
    data = { ...data, images: demoPhotos, isDemoPhoto: true };
  }

  return data;
}
