import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '@enums';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class OpenStreetMapService {
  format = '&format=json';
  addressDetails = '&addressdetails=1';

  constructor(private readonly http: HttpClient) {}

  getDistrict(lat, lon) {
    const headers = {
      'Accept-Language': localStorage.getItem(LocalStorageEnum.CURRENT_LANGUAGE) || 'en',
    };
    return this.http.get(`${environment.reverseMapUrl}lat=${lat}&lon=${lon}${this.addressDetails}${this.format}`, {
      headers,
    });
  }

  getDistrictCoordinate(dis) {
    return this.http.get(
      `${environment.mapUrl}q=${dis}&polygon_geojson=1&type=country&limit=1&${this.addressDetails}${this.format}`,
    );
  }
}
