import { Component, ComponentFactoryResolver, Injector, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env';
import { ILocation, IPropertyMapModel } from '@interfaces';
import { select, Store } from '@ngrx/store';
import {
  GeoService,
  injectDestroyService,
  MapService,
  MapSettingService,
  OpenStreetMapService,
  provideDestroyService,
} from '@service';
import {
  AppState,
  selectMapCenter,
  selectMapZoom,
  selectPropertiesList,
  selectPropertiesMap,
  selectSearchItemSelected,
  selectSearchParams,
} from '@store';
import { LatLngBounds, Map, Marker } from 'leaflet';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { ModalMarkersInClusterComponent, ModalWhenClickLabelOnLabelComponent } from '..';
import * as FilterActions from '../../../store/actions/search-page.actions';
import { MoneyShortStringPipe, NumberToShortStringPipe } from '@pipes';
import { LocalStorageEnum } from '@enums';
import { removeNonUniquePartInsideAddressUtil } from '../../helpers/remove-non-unique-part-isnide-address.util';
import { LocalStorageService } from '../../services/local-storage.service';
import { generateFullAddress } from '@helpers';

@Component({
  selector: 'app-map',
  template: `
    <div id="map">
      <div class="spinner-large" [hidden]="loading"></div>
    </div>
  `,
  styleUrls: ['./map.component.scss'],
  providers: [provideDestroyService()],
})
export class MapComponent implements OnInit, OnDestroy {
  public map: Map;
  public marker: Marker;
  public loading = false;
  public clusters = this.mapService.L.markerClusterGroup(this.mapSetting.defaultStyleClusters);
  public propertyListCoordinates: IPropertyMapModel[] = [];
  public propertiesList: IPropertyMapModel[] = [];

  private readonly destroy$ = injectDestroyService();

  markersArray = [];
  maxBounds: any;
  propertyInsideMapBounds = [];
  propertyListCoordinatesLocal;
  isOpenModalWithClusterMarkers = false;
  activeProperty;
  popupRef;
  zoomEndTimer;
  eventsAreCreated = false;
  mapMoveTimer;

  private readonly responseGetDistrict$: Subject<void>;
  private readonly responseGetDistrictCoordinate$: Subject<void>;
  private readonly mapMarkers$: Subject<AppState>;

  private isFirstInit = true;

  constructor(
    public mapService: MapService,
    public mapSetting: MapSettingService,
    private osm: OpenStreetMapService,
    private store: Store<AppState>,
    private componentFactoryResolver: ComponentFactoryResolver,
    private numberToShortStringPipe: NumberToShortStringPipe,
    private moneyShortStringPipe: MoneyShortStringPipe,
    private ls: LocalStorageService,
    private injector: Injector,
    private geoService: GeoService,
  ) {
    this.responseGetDistrict$ = new Subject();
    this.responseGetDistrictCoordinate$ = new Subject();
    this.mapMarkers$ = new Subject<AppState>();
  }

  ngOnInit() {
    if (this.mapService.L) {
      this.initWithParams();
      this.changeLoadingSpinner();
    }
  }

  getPropertyList() {
    this.changeCoordinatesRect();
    this.store.pipe(select(selectPropertiesMap), takeUntil(this.destroy$)).subscribe((res: IPropertyMapModel[]) => {
      this.propertyListCoordinates = res;
      this.setMarkersCluster();
    });

    // TODO: needed for only demo photos
    this.store.pipe(select(selectPropertiesList), takeUntil(this.destroy$)).subscribe((res: IPropertyMapModel[]) => {
      this.propertiesList = res;
    });
  }

  setMarkersCluster() {
    if (this.hasUpdateMarkers()) {
      return;
    }

    if (!this.map) {
      return this.clusters.clearLayers();
    }

    if (this.propertyListCoordinates) {
      this.markersArray = [];
      this.propertyListCoordinatesLocal = this.propertyListCoordinates[0];
      this.maxBounds = this.map.getBounds();

      this.propertyInsideMapBounds = [...this.propertyListCoordinates];

      this.createMarkers();

      if (this.map) {
        this.map.addLayer(this.createLayers());
      }
    }

    this.clusters.on('click', (event) => {
      const data = this.markersArray.find((item) => event.layer.options.className.includes(item.options.id)).options;

      // TODO: needed for only demo photos
      if (!data.images?.length && environment.useDemoPhotos) {
        data.isDemoPhoto = true;

        const images = this.propertiesList.find((prop) => prop.id === data.id)?.images;

        if (images) {
          data.images = images;
        }
      }

      const popupSetting = {
        ...this.mapSetting.defaultSettingPopup,
        offset: this.calculateMarkerOffset(event.containerPoint, true),
        className: 'est-popup-image',
      };

      const popup = event.layer.bindPopup('', popupSetting);

      popup.setPopupContent(() => this.contentForPopupClickLabel(data));
      popup.openPopup();
      this.isOpenModalWithClusterMarkers = true;
    });

    this.clusters.on('clusterclick', (cluster) => {
      cluster.preventDefault;
      this.isOpenModalWithClusterMarkers = true;

      if (this.map.getZoom() >= 18) {
        const content = cluster.layer.getAllChildMarkers().map((item) => item.options);

        // TODO: needed for only demo photos
        if (environment.useDemoPhotos) {
          content.forEach((item) => {
            if (!item.images?.length) {
              item.isDemoPhoto = true;
              const images = this.propertiesList.find((prop) => prop.id === item.id)?.images;

              if (images) {
                item.images = images;
                item.mainImage = `${environment.imgBucketUrl}${item.images[0]}`;
              }
            }
          });
        }

        const popupSetting = {
          ...this.mapSetting.defaultSettingPopup,
          offset: this.calculateMarkerOffset(cluster.containerPoint, false),
          className: 'modal-cluster',
          maxWidth: 0,
          autoPan: false,
        };
        const popup = cluster.layer.bindPopup('', popupSetting);
        content?.forEach((item) => {
          item.fullAddress = generateFullAddress(item);
        });
        popup.setPopupContent((_) => this.contentForPopupSeveralPropertyWithOneAddress(content));
        popup.openPopup();
        popup.on('popupclose', (_) => {
          this.isOpenModalWithClusterMarkers = false;
        });
      }
    });
  }

  calculateMarkerOffset(markerPos: { x: number; y: number }, isSingle: boolean): number[] {
    const res = isSingle ? [0, 50] : [0, 10];
    const popupWidth = isSingle ? 210 : 420;
    const popupHeight = isSingle ? 140 : 270;
    const mapSize = this.map.getSize();

    if (markerPos.x < popupWidth / 2) {
      res[0] = popupWidth / 2 - markerPos.x;
    } else if (markerPos.x > mapSize.x - popupWidth / 2) {
      res[0] = mapSize.x - markerPos.x - popupWidth / 2;
    }

    if (markerPos.y < popupHeight) {
      res[1] = -1 * (markerPos.y - popupHeight - 30);
    }
    return res;
  }

  hasUpdateMarkers(): boolean {
    return this.propertyListCoordinates && this.propertyListCoordinatesLocal === this.propertyListCoordinates[0];
  }

  showActiveProperty(item: IPropertyMapModel) {
    if (this.activeProperty?.id === item.id) {
      return;
    }

    this.activeProperty = item;
    const selectEl = this.markersArray.find((m) => m.options.id === item.id);

    if (selectEl) {
      this.marker = this.mapService.L.marker(
        this.mapService.L.latLng(selectEl.options.location.lat, selectEl.options.location.long),
      );

      this.map.addLayer(this.marker);
      const data = { ...selectEl.options, images: item.images, isDemoPhoto: item.isDemoPhoto };

      const parentSelectEl = this.clusters.getVisibleParent(selectEl);
      if (!parentSelectEl) {
        return;
      }

      const pixelPoint = this.map.latLngToContainerPoint(parentSelectEl.getLatLng());

      const popupSetting = {
        ...this.mapSetting.defaultSettingPopup,
        offset: this.calculateMarkerOffset(pixelPoint, true),
        className: 'est-popup-image',
        width: 201,
      };
      this.popupRef = parentSelectEl.bindPopup('', popupSetting);

      this.popupRef.setPopupContent((_) => this.contentForPopupClickLabel(data));
      this.popupRef.openPopup();
    }
  }

  clearActiveProperty(): void {
    if (this.popupRef) {
      this.activeProperty = undefined;
      this.popupRef.closePopup();
      this.popupRef = undefined;
    }
  }

  createMarkers() {
    this.propertyInsideMapBounds.forEach((item: IPropertyMapModel) => {
      const cluster = this.mapService.L.marker({ lon: item.location.long, lat: item.location.lat }, { ...item });
      let priceLabel = '00K';

      priceLabel = `${item.priceSymbol} ${this.moneyShortStringPipe.transform(item.price)}`;

      cluster.bindTooltip(priceLabel, {
        permanent: true,
        direction: 'top',
        className: ['label-marker', item.id].join(' '),
        interactive: true,
      });
      cluster.openTooltip();
      this.markersArray.push(cluster);
    });
  }

  iconCreateFunction(cluster) {
    return this.mapService.L.divIcon({
      html: `<div><span>${this.numberToShortStringPipe.transform(cluster.getChildCount())}</span></div>`,
      className: `marker-cluster marker-cluster-large`,
      iconSize: this.mapService.L.point(40, 40),
    });
  }

  createLayers() {
    this.clusters.clearLayers();

    let levelDefault;

    if (this.map.getZoom() <= 17) {
      this.clusters = this.mapService.L.markerClusterGroup({
        ...this.mapSetting.defaultStyleClusters,
        iconCreateFunction: this.iconCreateFunction.bind(this),
      });
      levelDefault = this.clusters.addLayers(this.markersArray);
    }

    if (this.map.getZoom() >= 18) {
      this.clusters = this.mapService.L.markerClusterGroup({
        maxClusterRadius: 0,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
        iconCreateFunction: this.iconCreateFunction.bind(this),
      });
      levelDefault = this.clusters.addLayers(this.markersArray);
    }

    return levelDefault;
  }

  changeCoordinatesRect() {
    const bounds: LatLngBounds = this.map.getBounds();
    const coordinatesRect = {
      topLat: bounds.getNorthWest().lat,
      topLong: bounds.getNorthWest().lng,
      botLat: bounds.getSouthEast().lat,
      botLong: bounds.getSouthEast().lng,
    };
    const { lat, lng } = this.map.getCenter();
    const mapCenter: ILocation = { lat, long: lng };
    const zoom = this.map.getZoom();

    // we need this timeout
    // It looks like window:resize event in search component is triggered after this map events sometimes
    // This is very strange...
    // This is very strange...
    clearTimeout(this.zoomEndTimer);
    this.zoomEndTimer = setTimeout(() => {
      this.mapService.updateInitParams({ zoom, coordinatesRect, mapCenter });
      this.store.dispatch(FilterActions.changeCoordinatesRect({ coordinatesRect }));
      this.store.dispatch(FilterActions.saveMapCenter({ mapCenter, zoom }));
    }, 100);
  }

  createMapEvents() {
    this.map.on('zoomstart', (_) => this.clusters.clearLayers());

    this.map.on('zoomend', (_) => {
      this.map.addLayer(this.createLayers());
      this.changeCoordinatesRect();
    });

    this.map.on('movestart', (_) => {
      this.clusters.clearLayers();
      this.mapMoveTimer = setTimeout(() => this.map.fireEvent('moveend'), 1500);
    });

    this.map.on('moveend', (_) => {
      if (this.mapMoveTimer) {
        clearTimeout(this.mapMoveTimer);
      }
      setTimeout((_) => this.map.addLayer(this.createLayers()));

      this.changeCoordinatesRect();
      this.mapService.updateInitParams({ searchItemSelected: undefined });
    });

    this.map.on('click', (_) => {
      // TODO: Replace obsolete code
      this.isOpenModalWithClusterMarkers = false;
    });
  }

  contentForPopupSeveralPropertyWithOneAddress(markers) {
    // TODO: need to use some other implementation for this, without factoryResolver and injector
    const factory = this.componentFactoryResolver.resolveComponentFactory(ModalMarkersInClusterComponent);
    const component = factory.create(this.injector);
    component.instance.markers = markers.map((item) => ({
      ...item,
      fullAddress: removeNonUniquePartInsideAddressUtil(item.fullAddress),
    }));
    component.changeDetectorRef.detectChanges();
    setInterval((_) => component.changeDetectorRef.detectChanges(), 0);
    return component.location.nativeElement;
  }

  contentForPopupClickLabel(marker) {
    // TODO: need to use some other implementation for this, without factoryResolver and injector
    const factory = this.componentFactoryResolver.resolveComponentFactory(ModalWhenClickLabelOnLabelComponent);
    const component = factory.create(this.injector);

    component.instance.marker = marker;
    component.changeDetectorRef.detectChanges();
    setInterval((_) => component.changeDetectorRef.detectChanges(), 300);
    return component.location.nativeElement;
  }

  public clearClusterOverlay(): void {
    setTimeout(() => {
      document
        .querySelectorAll('.leaflet-overlay-pane > .leaflet-zoom-animated > g > path[fill-opacity="0.4"]')
        ?.forEach((el) => el.remove());
    }, 100);
  }

  public initWithParams(): void {
    combineLatest([
      this.store.pipe(select(selectSearchParams)),
      this.store.pipe(select(selectSearchItemSelected)),
      this.store.pipe(select(selectMapCenter)),
      this.store.pipe(select(selectMapZoom)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((oldVal, newVal) => {
          return JSON.stringify(oldVal[2]) === JSON.stringify(newVal[2]);
        }),
      )
      .subscribe(([searchParams, searchItemSelected, mapCenter, zoom]) => {
        if (mapCenter || searchItemSelected) {
          const lat = mapCenter ? `${mapCenter.lat}` : searchItemSelected.lat;
          const lon = mapCenter ? `${mapCenter.long}` : searchItemSelected.lon;
          this.initMap(lat, lon, zoom);
        } else if (searchParams) {
          this.getDistrictCoordinateByName(searchParams.name || searchParams.city);
        } else {
          if (!this.ls.getItem(LocalStorageEnum.GeoUserInfo)) {
            this.geoService
              .getUserInfo()
              .pipe(switchMap((res) => this.geoService.getCityUser(res.headers.get('CF-Connecting-IP'))))
              .subscribe((res) => {
                this.ls.setItem(LocalStorageEnum.GeoUserInfo, res.body);
                const [lat, lon] = this.ls.getItem(LocalStorageEnum.GeoUserInfo).loc.split(',');
                this.initMap(lat, lon);
              });
          } else {
            const [lat, lon] = this.ls.getItem(LocalStorageEnum.GeoUserInfo).loc.split(',');
            this.initMap(lat, lon);
          }
        }
      });

    this.store.pipe(select(selectSearchItemSelected), takeUntil(this.destroy$)).subscribe((res) => {
      this.mapService.removeMarker(this.mapService.marker);
      if (res) {
        this.mapService.flyTo(+res.lat, +res.lon, this.mapSetting.defaultZoomMap);
        this.store.dispatch(FilterActions.clearFoundAddressItem());
        setTimeout(() => {
          this.mapService.marker = this.mapService.addMarker(+res.lat, +res.lon);
        }, 100);
      }
    });
  }

  changeLoadingSpinner() {
    this.loading = !this.loading;
  }

  // getDistrictFigure(lat, lon) {
  //   this.responseGetDistrict$.next();
  //   this.osm
  //     .getDistrict(lat, lon)
  //     .pipe(takeUntil(this.responseGetDistrict$))
  //     .subscribe((district: District) => {
  //       const request = district.address.city_district ? 'city_district' : 'neighbourhood';
  //
  //       this.responseGetDistrictCoordinate$.next();
  //       this.osm
  //         .getDistrictCoordinate(district.address[request])
  //         .pipe(takeUntil(this.responseGetDistrictCoordinate$))
  //         .subscribe((res) => {
  //           // @ts-ignore
  //           const [result] = res;
  //           const [coordinatesArr] = result.geojson.coordinates;
  //
  //           this.mapService.createPolygon(this.map, coordinatesArr);
  //         });
  //     });
  // }

  getDistrictCoordinateByName(name: string): void {
    this.osm
      .getDistrictCoordinate(name)
      .pipe(takeUntil(this.responseGetDistrictCoordinate$))
      .subscribe((res) => {
        // @ts-ignore
        const [addressObj] = res;
        const { lon, lat } = addressObj;
        this.initMap(lat, lon);
      });
  }

  initMap(lat: string, lon: string, zoom = this.mapSetting.defaultZoomMap): void {
    this.clearClusterOverlay();
    if (!this.map) {
      this.map = this.mapService.setupMap({ lat, lon, zoom });
      if (!this.eventsAreCreated) {
        setTimeout(() => this.createMapEvents());
        this.eventsAreCreated = true;
      }
      this.getPropertyList();
    } else {
      this.mapService.flyTo(+lat, +lon);
    }
    //this.setMarkersCluster();
    setTimeout(() => {
      if (this.isFirstInit) {
        this.isFirstInit = false;
        this.map.invalidateSize();
        this.mapService.tileLayer.redraw();
        return;
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.map.off('zoomend');
    this.map.off('moveend');
    this.responseGetDistrict$.next();
    this.responseGetDistrictCoordinate$.next();
    this.mapMarkers$.next(undefined);
  }
}
