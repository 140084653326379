import { createAction, props } from '@ngrx/store';
import { FilterNameEnum, PropertySortEnum, FilterControlNameEnum } from '@enums';
import {
  ICoordinatesRect,
  ILocation,
  IPropertySearchListResponse,
  IPropertySearchMapResponse,
  ISearchParams,
} from '@interfaces';

export const performSearch = createAction('[FILTERS] PERFORM SEARCH');

export const filterChanged = createAction(
  '[FILTERS] FILTER CHANGE',
  props<{ data: { [key in FilterNameEnum]?: any } }>(),
);

export const pageNumberChanged = createAction('[FILTERS] PAGE NUMBER CHANGED', props<{ pageNumber: number }>());

export const filterHidden = createAction('[FILTERS] FILTER HIDDEN', props<{ data: FilterControlNameEnum[] }>());

export const changeCoordinatesRect = createAction(
  '[FILTERS] CHANGE COORDINATES RECT',
  props<{ coordinatesRect: ICoordinatesRect }>(),
);

export const sortOrderChanged = createAction('[FILTERS] SORT ORDER CHANGED', props<{ data: PropertySortEnum }>());

export const resetAllFilters = createAction('[FILTERS] RESET ALL FILTER');

export const propertyMapChanged = createAction('[FILTERS] PROPERTY MAP CHANGED', props<IPropertySearchMapResponse>());

export const propertyListChanged = createAction(
  '[FILTERS] PROPERTY LIST CHANGED',
  props<IPropertySearchListResponse>(),
);

export const setShowMap = createAction('[FILTERS] SET SHOW MAP', props<{ isShowMap: boolean }>());

export const setNarrowMode = createAction('[FILTERS] SET NARROW MODE', props<{ isNarrowMode: boolean }>());

export const setSearchParams = createAction(
  '[FILTERS] SET SEARCH PARAMS',
  props<{ searchParams: ISearchParams; item?: any; zoom?: number }>(),
);

export const clearFoundAddressItem = createAction('[FILTERS] CLEAR FOUND ADDRESS ITEM');

export const saveMapCenter = createAction('[FILTERS] SAVE MAP CENTER', props<{ mapCenter: ILocation; zoom: number }>());

export const initSavedParams = createAction('[FILTERS] INIT SAVED PARAMS');

export const clearPending = createAction('[FILTERS] CLEAR PENDING');

export const clearSearchPage = createAction('[FILTERS] CLEAR SEARCH PAGE');

export const saveSearch = createAction('[FILTERS] SAVE SEARCH');

export const renewSearch = createAction('[FILTERS] RENEW SEARCH', props<{ data: any }>());

export const pendingSaveSearch = createAction('[FILTERS] PENDING SAVE SEARCH', props<{ data: boolean }>());

export const waitingSaveSearch = createAction('[FILTERS] WAITING SAVE SEARCH', props<{ data: number }>());
