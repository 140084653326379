import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocalStorageEnum } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public isBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public getItem(key: string): any {
    if (!this.isBrowser) {
      return null;
    }

    if (!localStorage.getItem(key)) {
      return null;
    }

    if (['undefined', 'null'].includes(localStorage.getItem(key))) {
      return null;
    }

    return JSON.parse(localStorage.getItem(key));
  }

  public setItem(key: string, value: any): void {
    if (!this.isBrowser) {
      return null;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }

  public updateItemsArray(key: string, id: number, remove: boolean = false, start?: number, end?: number): void {
    let items = this.getItem(key) ?? [];

    if (remove) {
      items = items.filter((item) => item !== id);
      this.setItem(key, [...new Set([...items])]);
      return;
    }

    if (start && end) {
      items = items.length >= 10 ? items.slice(0, 9) : items;
    }

    this.setItem(key, [...new Set([id, ...items])]);
  }

  public updateItem(key: string, value: any): void {
    const data = this.getItem(key);
    if (data) {
      this.setItem(key, JSON.stringify({ ...data, value }));
    }
  }

  public removeItem(key: string): void {
    if (!this.isBrowser) {
      return null;
    }
    localStorage.removeItem(key);
  }

  public initLocalStorage() {
    if (!this.getItem(LocalStorageEnum.HIDDEN_PROPERTIES)) {
      this.setItem(LocalStorageEnum.HIDDEN_PROPERTIES, []);
    }

    if (!this.getItem(LocalStorageEnum.SearchFilter)) {
      this.setItem(LocalStorageEnum.SearchFilter, {});
    }
  }
}
