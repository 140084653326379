import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagingComponent } from './paging.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PagingComponent],
  imports: [CommonModule, TranslateModule],
  exports: [PagingComponent],
})
export class PagingModule {}
