import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationFilter',
})
export class NotificationFilterPipe implements PipeTransform {
  transform(list: any[], event: any): any {
    return list.filter((item) => item.markDeleted === false);
  }
}
