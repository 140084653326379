import { IPropertyListModel } from '@interfaces';
import { createReducer, on } from '@ngrx/store';
import * as SavedHomesActions from '@store';

export interface ISavedHomesState {
  list: IPropertyListModel[];
}

export const initialSavedHomesState: ISavedHomesState = {
  list: [],
};

export const savedHomesReducer = createReducer(
  initialSavedHomesState,
  on(SavedHomesActions.loadSavedHomes, (state, action) => {
    return {
      ...state,
      list: action.data,
    };
  }),
  on(SavedHomesActions.addSavedHomes, (state, action) => {
    const listRaw = [...state.list];
    listRaw.unshift(action.data);

    return {
      ...state,
      list: listRaw,
    };
  }),
  on(SavedHomesActions.removeSavedHomes, (state, action) => {
    return {
      ...state,
      list: state.list.filter((item) => item.id !== action.data.id),
    };
  }),
  on(SavedHomesActions.clearSavedHomes, (state, action) => {
    return {
      ...state,
      list: [],
    };
  }),
);
