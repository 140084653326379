export enum ModalEnum {
  AUTH,
  SAVE_SEARCH,
  SHARE,
  TAKE_TOUR,
  CONTACT_AGENT,
  CONFIRM_DELETE,
  MOBILE_MENU,
  PROPERTY_TYPE,
  FILTER_MORE_MOBILE,
}
