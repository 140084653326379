<div class="input-block">
  <input
    value="{{ value }}"
    [type]="type"
    [required]="required"
    (keyup)="change($event)"
    (click)="click()"
    [disabled]="readonly"
    [ngStyle]="{ 'border-color': borderColor ? borderColor : '' }"
  />
  <label>
    {{ label | translate }}
    <span class="required" *ngIf="required">*</span>
  </label>
  <span class="error">{{ errorText | translate }}</span>
</div>
