import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageEnum } from '@enums';
import { VALID_TOKEN } from '@helpers';
import { IAuth, IRegister, IToken } from '@interfaces';
import { Store } from '@ngrx/store';
import { REFRESH_TOKEN, REGISTER, SIGN_IN, SIGN_OUT, SIGN_OUT_ALL } from '../endpoints';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly http: HttpClient,
    private ls: LocalStorageService,
    private router: Router,
    private store: Store,
  ) {}

  public signIn(data: IAuth) {
    return this.http.post(SIGN_IN, data);
  }

  public signUp(data: IRegister) {
    return this.http.post(REGISTER, data);
  }

  public signOut() {
    return this.http.put(SIGN_OUT, null, {
      headers: {
        Authorization: `Bearer ${this.ls.getItem(LocalStorageEnum.ACCESS_TOKEN)}`,
      },
    });
  }

  public signOutAll() {
    return this.http.put(SIGN_OUT_ALL, null, {
      headers: {
        Authorization: `Bearer ${this.ls.getItem(LocalStorageEnum.ACCESS_TOKEN)}`,
      },
    });
  }

  public isLoggedIn() {
    return this.ls.getItem(VALID_TOKEN) !== null;
  }

  public saveToken(token: IToken) {
    this.ls.setItem(VALID_TOKEN, '1');
    this.ls.setItem(LocalStorageEnum.ACCESS_TOKEN, token.access_token);
    this.ls.setItem(LocalStorageEnum.REFRESH_TOKEN, token.refresh_token);
  }

  public deleteToken() {
    this.ls.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    this.ls.removeItem(LocalStorageEnum.REFRESH_TOKEN);
    this.ls.removeItem(VALID_TOKEN);
  }

  public deleteAccessToken() {
    this.ls.removeItem(LocalStorageEnum.ACCESS_TOKEN);
  }

  public getToken() {
    return this.ls.getItem(LocalStorageEnum.ACCESS_TOKEN);
  }

  public refreshToken() {
    this.deleteAccessToken();
    return this.http.put<any>(REFRESH_TOKEN, null, {
      headers: {
        Authorization: `Bearer ${this.ls.getItem(LocalStorageEnum.REFRESH_TOKEN)}`,
      },
    });
  }

  public getClientId(): string {
    if (this.ls.getItem(LocalStorageEnum.DEVICE_ID)) {
      return this.ls.getItem(LocalStorageEnum.DEVICE_ID);
    }

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    this.ls.setItem(LocalStorageEnum.DEVICE_ID, uuid);

    return uuid;
  }
}
