import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { AuthService } from '@service';

@Injectable()
export class AuthEffects {
  constructor(
    private http: HttpClient,
    private actions: Actions,
    private authService: AuthService,
  ) {}

  // refreshToken$ = createEffect(
  //   () =>
  //     this.actions.pipe(
  //       ofType(HeaderActions.refreshToken),
  //       switchMap(() =>
  //         interval(60000).pipe(
  //           switchMap(() =>
  //             this.http.put<any>(REFRESH_TOKEN, null)
  //               .pipe(
  //                 map((response) => {
  //                   this.authService.deleteToken();
  //                   this.authService.saveToken(response)
  //                 })
  //               ),
  //           ),
  //         ),
  //       ),
  //     ),
  //   { dispatch: false },
  // );
}
