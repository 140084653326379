import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { AppRoutingModule } from '../../app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { BasicModalModule } from '../../shared/modules/basic-modal/basic-modal.module';
import { DirectivesModule, SearchHighlightModule } from '@directives';
import { LanguageSelectComponent } from '../../shared/component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    BasicModalModule,
    ReactiveFormsModule,
    SearchHighlightModule,
    DirectivesModule,
    LanguageSelectComponent,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
