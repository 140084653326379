<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [formGroup]="saveSearchForm">
  <app-input
    [label]="description"
    (keyup)="change($event.target)"
    [autofocus]="true"
    formControlName="nameSearch"
  ></app-input>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn apply"
    *ngIf="applyButton"
    [disabled]="!valueInput"
    (click)="activeModal.close(valueInput)"
  >
    {{ applyButton | translate }}
  </button>
</div>
