import { createReducer, on } from '@ngrx/store';
import * as HeaderActions from './../actions/header.actions';

export interface IHeaderState {
  loggedUser: boolean;
}

export const initialHeaderState = {
  loggedUser: false,
};

export const headerReducer = createReducer(
  initialHeaderState,
  on(HeaderActions.updateLoggedState, (state, action) => ({ ...state, loggedUser: action.data })),
);
