import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlurOnEnterDirective } from './blur-on-enter.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { PseudoZoomDirective } from './pseudo-image-zoom.directive';
import { ImageErrorDirective } from './image-error.directive';
import { LessThanOrEqualValidatorDirective } from './less-than-or-equal.directive';
import { LazyLoadImageDirective } from './lazy-load-image';

@NgModule({
  declarations: [
    BlurOnEnterDirective,
    ClickOutsideDirective,
    PseudoZoomDirective,
    ImageErrorDirective,
    LazyLoadImageDirective,
    LessThanOrEqualValidatorDirective,
  ],
  imports: [CommonModule],
  exports: [
    BlurOnEnterDirective,
    ClickOutsideDirective,
    PseudoZoomDirective,
    ImageErrorDirective,
    LazyLoadImageDirective,
    LessThanOrEqualValidatorDirective,
  ],
})
export class DirectivesModule {}
