import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { BRAND_APP } from '@helpers';
import { select, Store } from '@ngrx/store';
import { AppState, selectPageNumber, selectPendingList, selectPropertiesList, selectPropertiesListCount } from '@store';
import { IPropertyListModel } from '@interfaces';
import { provideDestroyService } from '@service';
import { environment } from '@env';
import * as SearchPageActions from '@store';

@Component({
  selector: 'app-property-list-panel',
  templateUrl: './property-list-panel.component.html',
  styleUrls: ['./property-list-panel.component.scss'],
  providers: [provideDestroyService()],
})
export class PropertyListPanelComponent implements OnInit {
  @Output() showOnMapAction = new EventEmitter<IPropertyListModel>();
  @Output() clearShowOnMapAction = new EventEmitter();

  public readonly environment = environment;

  public propertyList$ = this.store.pipe(select(selectPropertiesList));
  public propertyListCount$ = this.store.pipe(select(selectPropertiesListCount));
  public pageNumber$ = this.store.pipe(select(selectPageNumber));
  public pending$ = this.store.pipe(select(selectPendingList));

  constructor(
    public store: Store<AppState>,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {}

  public onShowOnMapAction(item: IPropertyListModel): void {
    this.showOnMapAction.emit(item);
  }

  public onClearShowOnMapAction(): void {
    this.clearShowOnMapAction.emit();
  }

  public onPageChanged(pageNumber: number): void {
    this.store.dispatch(SearchPageActions.pageNumberChanged({ pageNumber }));
    const element = this.elementRef.nativeElement.querySelector('.properties-wrapper');
    if (element) {
      element.scrollTop = 0;
    }
  }

  protected readonly BRAND_APP = BRAND_APP;
}
