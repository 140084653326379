<div class="drop-zone" (click)="fileInput.click()" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
  <ng-container *ngIf="!isDragging">
    <span>{{'image_uploader.title_main' | translate}}</span>
  </ng-container>
  <ng-container *ngIf="isDragging">
    <span>{{'image_uploader.title_dragging' | translate}}</span>
  </ng-container>
</div>
<input #fileInput type="file" (change)="onFileSelected($event)" multiple accept=".jpg, .jpeg, .png" style="display: none">
<div *ngIf="isDragging" class="drop-overlay" (dragleave)="onDragLeave($event)"></div>
