<div style="position: relative">
  <div class="address-incomplete" *ngIf="!property.isActiveStatus && !isCorrectLocation">{{'own_properties_details.error_address'| translate}}</div>
  <span class="hidden-property" *ngIf="!property.isActiveStatus">{{'own_properties_details.in_active'| translate}}</span>
  <div
    class="object-item"
    [class.greyed-out]="!property.isActiveStatus"
    (mouseover)="showOnMap(property)"
    (mouseleave)="clearShowOnMap()"
  >
    <div (click)="goToDetailsProperty($event, property)">
      <app-slider
        [photos]="property?.images"
        [isDemoPhoto]="property?.isDemoPhoto"
        [hidePhoto]="false"
      ></app-slider>

      <ng-container *ngIf="buttonFunction === 'favorite' && !property?.isSavedHome">
        <div class="favorite" (click)="addToSaved(property)">
          <svg viewBox="0 0 24 22">
            <path class="fill"
                  d="M17.3996 6.17511e-05C15.5119 0.00908657 13.7078 0.779206 12.3955 2.13608L11.9995 2.54408L11.6035 2.13608C10.2912 0.779206 8.48708 0.00908657 6.59946 6.17511e-05C5.15317 -0.00630912 3.7479 0.480456 2.61543 1.38007C1.08163 2.60976 0.137114 4.42893 0.0137749 6.39093C-0.109564 8.35294 0.5997 10.2761 1.96743 11.6882L2.51943 12.2522L11.3995 21.3482C11.5575 21.5095 11.7738 21.6004 11.9995 21.6004C12.2253 21.6004 12.4415 21.5095 12.5995 21.3482L21.4796 12.2522L22.0316 11.6882C23.3993 10.2761 24.1086 8.35294 23.9852 6.39093C23.8619 4.42893 22.9174 2.60976 21.3836 1.38007C20.2511 0.480456 18.8458 -0.00630912 17.3996 6.17511e-05Z"></path>
            <path class="outline"
                  d="M12.3955 2.13608C13.7078 0.779206 15.5119 0.00908657 17.3996 6.17511e-05C18.8458 -0.00630912 20.2511 0.480456 21.3836 1.38007C22.9174 2.60976 23.8619 4.42893 23.9852 6.39093C24.1086 8.35294 23.3993 10.2761 22.0316 11.6882L21.4796 12.2522L12.5995 21.3482C12.4415 21.5095 12.2253 21.6004 11.9995 21.6004C11.7738 21.6004 11.5575 21.5095 11.3995 21.3482L2.51943 12.2522L1.96743 11.6882C0.5997 10.2761 -0.109564 8.35294 0.0137748 6.39093C0.137114 4.42893 1.08163 2.60976 2.61543 1.38007C3.7479 0.480456 5.15317 -0.00630912 6.59946 6.17511e-05C8.48708 0.00908657 10.2912 0.779206 11.6035 2.13608L11.9995 2.54408L12.3955 2.13608ZM19.8956 3.25208C19.1854 2.69122 18.3045 2.39053 17.3996 2.40008C16.1576 2.41525 14.9717 2.91978 14.0995 3.80409L13.7155 4.21209L12.4315 5.5321C12.1927 5.77011 11.8063 5.77011 11.5675 5.5321L10.2835 4.21209L9.8995 3.80409C9.0273 2.91978 7.84145 2.41525 6.59947 2.40008C5.69165 2.39734 4.81045 2.70661 4.10345 3.27608C3.09352 4.06928 2.47292 5.25804 2.39944 6.54011C2.31914 7.81608 2.78104 9.06669 3.67145 9.98414L4.22345 10.5601L11.9995 18.5162L19.8476 10.5601L20.3996 9.98414C21.2638 9.05458 21.6991 7.80545 21.5996 6.54011C21.5329 5.2495 20.9116 4.05071 19.8956 3.25208Z"></path>
          </svg>
        </div>
      </ng-container>

      <ng-container *ngIf="buttonFunction === 'favorite' && property?.isSavedHome">
        <div class="favorite delete out-favorite-bg" (click)="deleteSaved(property)">
          <svg viewBox="0 0 24 22">
            <path class="fill"
                  d="M17.3996 6.17511e-05C15.5119 0.00908657 13.7078 0.779206 12.3955 2.13608L11.9995 2.54408L11.6035 2.13608C10.2912 0.779206 8.48708 0.00908657 6.59946 6.17511e-05C5.15317 -0.00630912 3.7479 0.480456 2.61543 1.38007C1.08163 2.60976 0.137114 4.42893 0.0137749 6.39093C-0.109564 8.35294 0.5997 10.2761 1.96743 11.6882L2.51943 12.2522L11.3995 21.3482C11.5575 21.5095 11.7738 21.6004 11.9995 21.6004C12.2253 21.6004 12.4415 21.5095 12.5995 21.3482L21.4796 12.2522L22.0316 11.6882C23.3993 10.2761 24.1086 8.35294 23.9852 6.39093C23.8619 4.42893 22.9174 2.60976 21.3836 1.38007C20.2511 0.480456 18.8458 -0.00630912 17.3996 6.17511e-05Z"></path>
            <path class="outline"
                  d="M12.3955 2.13608C13.7078 0.779206 15.5119 0.00908657 17.3996 6.17511e-05C18.8458 -0.00630912 20.2511 0.480456 21.3836 1.38007C22.9174 2.60976 23.8619 4.42893 23.9852 6.39093C24.1086 8.35294 23.3993 10.2761 22.0316 11.6882L21.4796 12.2522L12.5995 21.3482C12.4415 21.5095 12.2253 21.6004 11.9995 21.6004C11.7738 21.6004 11.5575 21.5095 11.3995 21.3482L2.51943 12.2522L1.96743 11.6882C0.5997 10.2761 -0.109564 8.35294 0.0137748 6.39093C0.137114 4.42893 1.08163 2.60976 2.61543 1.38007C3.7479 0.480456 5.15317 -0.00630912 6.59946 6.17511e-05C8.48708 0.00908657 10.2912 0.779206 11.6035 2.13608L11.9995 2.54408L12.3955 2.13608ZM19.8956 3.25208C19.1854 2.69122 18.3045 2.39053 17.3996 2.40008C16.1576 2.41525 14.9717 2.91978 14.0995 3.80409L13.7155 4.21209L12.4315 5.5321C12.1927 5.77011 11.8063 5.77011 11.5675 5.5321L10.2835 4.21209L9.8995 3.80409C9.0273 2.91978 7.84145 2.41525 6.59947 2.40008C5.69165 2.39734 4.81045 2.70661 4.10345 3.27608C3.09352 4.06928 2.47292 5.25804 2.39944 6.54011C2.31914 7.81608 2.78104 9.06669 3.67145 9.98414L4.22345 10.5601L11.9995 18.5162L19.8476 10.5601L20.3996 9.98414C21.2638 9.05458 21.6991 7.80545 21.5996 6.54011C21.5329 5.2495 20.9116 4.05071 19.8956 3.25208Z"></path>
          </svg>
        </div>
      </ng-container>

      <ng-container *ngIf="buttonFunction === 'activation'">
        <div
          class="activation-button edit "
          [ngbTooltip]="'own_properties_details.edit'| translate"
          (click)="goToDetailsProperty($event, property)">
          <svg enable-background="new 0 0 500 500" height="24px" id="Layer_1" version="1.1" viewBox="0 0 500 500" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path clip-rule="evenodd" d="M82.474,215.547c-8.903,8.9-13.177,21.622-15.354,34.523  L32.503,413.794c-4.906,29.077,24.257,58.33,53.698,53.787l163.897-34.167c11.996-2.174,24.987-5.902,33.894-14.802L457.609,244.8  c13.904-13.982,13.904-36.608,0-50.6L305.977,42.382c-13.992-13.902-36.615-13.902-50.516,0L82.474,215.547z M249.099,390.257  l-84.856,16.273l-70.319-70.415l16.169-84.954l126.376-126.557c6.996-7,18.267-7,25.263,0c6.999,6.992,6.999,18.348,0,25.35  L144.888,266.973c-8.717,8.723-8.717,22.898,0,31.613c8.725,8.731,22.807,8.731,31.528,0l116.925-117.02  c7.005-6.993,18.26-6.993,25.26,0c7.005,7,7.005,18.268,0,25.259L201.766,323.846c-8.723,8.721-8.723,22.899,0,31.622  c8.722,8.719,22.805,8.719,31.524,0l116.925-117.021c6.912-6.912,18.269-6.912,25.268,0c6.993,6.992,6.993,18.351,0,25.338  L249.099,390.257z" fill="#010101" fill-rule="evenodd"/></svg>
        </div>

        <div
          class="favorite activation-button"
          [class.disabled-activate]="!isCorrectLocation"
          [class.inactive]="!property.isActiveStatus"
          [ngbTooltip]="getTooltipText()"
          (click)="deleteSaved(property, $event)">
          <svg height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <path
                d="m494.8,241.4l-50.6-49.4c-50.1-48.9-116.9-75.8-188.2-75.8s-138.1,26.9-188.2,75.8l-50.6,49.4c-11.3,12.3-4.3,25.4 0,29.2l50.6,49.4c50.1,48.9 116.9,75.8 188.2,75.8s138.1-26.9 188.2-75.8l50.6-49.4c4-3.8 11.7-16.4 0-29.2zm-238.8,84.4c-38.5,0-69.8-31.3-69.8-69.8 0-38.5 31.3-69.8 69.8-69.8 38.5,0 69.8,31.3 69.8,69.8 0,38.5-31.3,69.8-69.8,69.8zm-195.3-69.8l35.7-34.8c27-26.4 59.8-45.2 95.7-55.4-28.2,20.1-46.6,53-46.6,90.1 0,37.1 18.4,70.1 46.6,90.1-35.9-10.2-68.7-29-95.7-55.3l-35.7-34.7zm355,34.8c-27,26.3-59.8,45.1-95.7,55.3 28.2-20.1 46.6-53 46.6-90.1 0-37.2-18.4-70.1-46.6-90.1 35.9,10.2 68.7,29 95.7,55.4l35.6,34.8-35.6,34.7z" />
            </g>
          </svg>
        </div>
      </ng-container>

    </div>

    <div class="object-item-info">
      <div class="price-and-options">
        <div class="d-flex flex-grow-1">
          <span class="price flex-grow-1">
              {{ property.price | currency: property.priceCurrency :true:'1.0-0' }}
          </span>

          <span *ngIf="property.cardHomeType?.length && !property.cardHomeType.includes('undefined')" class="float-end home-type">
          {{ property.cardHomeType }}
        </span>
        </div>
      </div>

      <div class="options-block" [title]="optionsBlockTooltip">
        <p *ngFor="let item of property.cardInfoLine">
          <b *ngIf="item.count">{{ item.count }}</b> <span>{{ item.name }}</span>
        </p>
      </div>

      <div class="address" [title]="generateAddressUtils(property.fullAddress)">
        {{ generateAddressUtils(property.fullAddress) }}
      </div>
      <div class="info-line broker-line">
        <div>{{ property.brokerName }}</div>
        <app-days-on-market-format [daysOnEstate]="property?.actualDays ?? 0"></app-days-on-market-format>
      </div>
    </div>
  </div>
</div>