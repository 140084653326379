import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationFilterCount',
})
export class NotificationFilterCountPipe implements PipeTransform {
  transform(list: any, event: any): any {
    return list.filter((item) => item.markDeleted === false).length;
  }
}
