<div class="background" [id]="modalId" [class.visible]="isOpen">
  <div class="modal">
    <div class="close">
      <button (click)="close()">
        <svg viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img">
          <title>Close</title>
          <path stroke="none"
                d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"></path>
        </svg>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
