<div class="control-header" *ngIf="!!(propertyListCount$ | async);else noResult">
  <span class="result-count">
    {{ 'property_list.found_items' | translate: { '%itemsCount': (propertyListCount$ | async) } }}
  </span>
  <app-sort-order></app-sort-order>
</div>

<div
  class="properties-wrapper"
  [class.opacity-50]="(pending$ | async)"
  [class.no-paging]="(propertyListCount$ | async) <= environment.propertiesLoadLimit"
>
  <app-property-default-card
    *ngFor="let item of (propertyList$ | async)"
    [property]="item"
    (showOnMapAction)="onShowOnMapAction(item)"
    (clearShowOnMapAction)="onClearShowOnMapAction()"
  >
  </app-property-default-card>

  <app-paging
    *ngIf="(propertyListCount$ | async) > environment.propertiesLoadLimit"
    [class.opacity-50]="(pending$ | async)"
    [pageSize]="environment.propertiesLoadLimit"
    [currentPage]="pageNumber$ | async"
    [totalElements]="propertyListCount$ | async"
    (pageChanged)="onPageChanged($event)"
  >
  </app-paging>

  <div class="footer-content">
    <div class="logo" routerLink="/">
      {{ BRAND_APP }}
      <span>app</span>
      <span class="beta">Beta</span>
    </div>
  </div>
</div>

<ng-template #noResult>
  <div *ngIf="!(pending$ | async)" class="not-found">
    <p>{{ 'property_list.object_not_found' | translate }}</p>
  </div>
  <div *ngIf="(pending$ | async)" class="preloader">
    <div class="sp sp-circle"></div>
  </div>
  <!--  TODO: Add block for google ads here -->
</ng-template>
