export function getKeysFromEnum(value: string | string[], dictionary: any, isCapitalise = true): string[] | undefined {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return [];
  }

  const inputValues = Array.isArray(value) ? value : [value];
  const result: string[] = [];

  for (const inputValue of inputValues) {
    const key = Object.keys(dictionary).find((k) => dictionary[k] === inputValue.toString());
    if (key) {
      const capitalizedWord = key
        .toLowerCase()
        .replace(/(^|\s)\S/g, (match) => match.toUpperCase())
        .replace(/_/g, ' ');
      result.push(isCapitalise ? capitalizedWord : key);
    }
  }

  return result ?? [];
}
