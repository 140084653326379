<div class="paging">
  <button
    [class.hidden-button]="currentPage === 1"
    (click)="changePage(currentPage - 1)"
  >
    {{'paging.prev' | translate}}
  </button>
  <ul>
    <li *ngIf="showEllipsisBeforeFirstPage" class="ellipsis">
      <span>...</span>
    </li>
    <li *ngFor="let page of visiblePages" [class.active]="currentPage === page" (click)="changePage(page)">
      <a>{{ page }}</a>
    </li>
    <li *ngIf="showEllipsisAfterLastPage" class="ellipsis">
      <span>...</span>
    </li>
  </ul>
  <button [class.hidden-button]="currentPage === totalPages" (click)="changePage(currentPage + 1)">
    {{'paging.next' | translate}}
  </button>
</div>
