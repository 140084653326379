export const VALID_TOKEN = 'VALID_TOKEN';
export const ROLE_USER = 'ROLE_USER';
export const DEFAULT_LANG = 'en';
export const DEFAULT_CURRENCY_SYMBOL = '$';

export const regExp = {
  urlPageWithoutParam: /(\?[\w\d\W]{1,})/,
};

export const CONTAINER_MAP = 'map';
export const SERVER_SETTINGS = 'serverSettings';

export const TYPE_PROPERTY = {
  UNAVAILABLE: 'UNAVAILABLE',
  ACTIVE: 'ACTIVE',
};

export namespace LAST_SEARCH {
  export const MAP = 'LAST_USED_MAP_SETTING';
}

export const BRAND_APP = 'Zex';
