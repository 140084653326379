export const environment = {
  production: true,
  useBaseAuth: true,

  // Open Street Maps
  mapUrl: 'https://nominatim.openstreetmap.org/search.php?',
  reverseMapUrl: 'https://nominatim.openstreetmap.org/reverse.php?',

  // Tiles
  TILES_MAP: 'https://tiles.zexstage.com/{z}/{x}/{y}.png',

  // backend
  serverV3: 'https://dev-mailer.zexstage.com',
  serverFE: 'https://pijaybqcax.zexstage.com',

  propertiesLoadLimit: 40,
  propertiesLoadLimitForMap: 3000,
  propertiesLastViewedLimit: 10,

  imgBucketUrl: 'https://images.zexstage.com/',

  geoIpServiceToken: 'df631ccf6157eb',

  googleClientId: '382028096460-bmjq53meon3rpkhv592rh85hq217kr7r.apps.googleusercontent.com',

  useDemoPhotos: true,
  typeEnv: 'dev',

  proImagesMax_0: 16,
  proImagesMax_1: 35,
  proImagesMax_2: 100,
};
