import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyInputModule } from '../../../../shared/directives/numers-only-input/numbers-only-input.module';
import { YourHomesRoutingModule } from './your-homes-routing.module';
import { YourHomesComponent } from './your-homes.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { AddressSearchInputModule, ModalConfirmModule, SimpleMapModule } from '../../../../shared/component';
import { PagingModule } from '../../../../shared/modules/paging/paging.module';
import { BasicModalModule } from '../../../../shared/modules/basic-modal/basic-modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PropertyDefaultCardModule } from '../../../../shared/modules/property-default-card/property-default-card.module';
import { CustomElementsFormModule } from '../../../../shared/custom-elements-form.module';
import { ImagesListModule } from '../../../../shared/component/images-list/images-list.module';
import { YourPropertyDetailsComponent } from './property-details/your-property-details.component';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { DirectivesModule } from '@directives';
import { ImageUploaderV2Module } from '../../../../shared/component/image-uploader-v2/image-uploader-v2.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [YourHomesComponent, PropertyListComponent, YourPropertyDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    YourHomesRoutingModule,
    PagingModule,
    BasicModalModule,
    ModalConfirmModule,
    PropertyDefaultCardModule,
    CustomElementsFormModule,
    AddressSearchInputModule,
    ImagesListModule,
    SimpleMapModule,
    PipesModule,
    DirectivesModule,
    ImageUploaderV2Module,
    TranslateModule,
    NumbersOnlyInputModule,
    NgbTooltip,
  ],
})
export class YourHomesModule {}
