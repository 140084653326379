<ng-container *ngIf="daysOnEstate > 1">
  <div class="on-estate-finder">
    <span>{{ daysOnEstate }}</span> {{ 'days_on_market.days' | translate: { '%brand': brandApp } }}
  </div>
</ng-container>

<ng-container *ngIf="daysOnEstate === 1">
  <div class="on-estate-finder">{{ 'days_on_market.yesterday' | translate: { '%brand': brandApp } }}</div>
</ng-container>

<ng-container *ngIf="!daysOnEstate">
  <div class="on-estate-finder">{{ 'days_on_market.today' | translate: { '%brand': brandApp } }}</div>
</ng-container>
