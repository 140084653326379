import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressSearchInputComponent } from './address-search-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SearchHighlightModule, DirectivesModule } from '@directives';

@NgModule({
  declarations: [AddressSearchInputComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, SearchHighlightModule, DirectivesModule],
  exports: [AddressSearchInputComponent],
})
export class AddressSearchInputModule {}
