import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function lessThanOrEqualValidator(compareTo: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }

    const compareToControl = control.parent.get(compareTo);
    if (!compareToControl) {
      return null;
    }

    const controlValue = parseFloat(control.value);
    const compareToValue = parseFloat(compareToControl.value);

    if (isNaN(controlValue) || isNaN(compareToValue)) {
      return null;
    }

    return controlValue <= compareToValue ? null : { lessThanOrEqual: true };
  };
}
