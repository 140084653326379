<div class="modal-bg-default" (click)="close()"></div>
<div class="modal-400">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ content.title | translate }}</h4>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="content.status === STATUS.SUCCESS">
      <div class="success-request-icon"></div>
    </ng-container>
    <ng-container *ngIf="content.status === STATUS.ERROR">
      <div class="error-request-icon"></div>
    </ng-container>
    <p>{{ content.description | translate }}</p>
  </div>
  <div class="modal-footer"></div>
</div>
