import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBookingTour } from '../interfaces/booking-tour.interface';
import { AVAILABLE_TOURS, REQUEST_TOUR } from '../endpoints';
import { IRequestTourDto } from '@interfaces';
import { Observable } from 'rxjs';
import { IAvailableToursDto } from '../interfaces/dto/available-tours.dto';
import { generateHash, getCurrentUTCDate } from '@helpers';

@Injectable({
  providedIn: 'root',
})
export class TakeATourService {
  constructor(private http: HttpClient) {}

  // Don't see on UI how this method is called.
  // Need some clarification on it.
  public send(data: IBookingTour) {
    return this.http.post(REQUEST_TOUR, data);
  }

  public requestTour(data: IRequestTourDto): Observable<any> {
    return this.http.post(REQUEST_TOUR, data);
  }

  public getAvailableTours(propertyId: number): Observable<IAvailableToursDto> {
    return this.http.post<IAvailableToursDto>(AVAILABLE_TOURS, {
      property_id: propertyId,
      hash: generateHash(`${propertyId}`),
      startDate: getCurrentUTCDate().toISOString().split('T')[0],
      startHour: new Date().getHours(),
    });
  }
}
