import { createReducer, on } from '@ngrx/store';
import {
  FilterControlNameEnum,
  FilterNameEnum,
  LocalStorageEnum,
  PropertyBathNumberEnum,
  PropertyBedNumberEnum,
  PropertyHomeTypeEnum,
  PropertyParkingNumberEnum,
  PropertySortEnum,
  PropertyTypeEnum,
} from '@enums';

import {
  ICoordinatesRect,
  IInitSearchParams,
  ILocation,
  IPropertyListModel,
  IPropertyMapModel,
  IPropertySearchListResponse,
  IPropertySearchMapResponse,
  ISearchFilter,
  ISearchParams,
} from '@interfaces';
import { mapperDemoPhotosUtils } from '../../shared/helpers/mapper-demo-photos.utils';
import * as SearchPageActions from '../actions/search-page.actions';
import { calculateBoundingBox, getRadiusByMapZoom } from '../../shared/helpers/calc-bound-box.utils';

export interface SearchPageState {
  searchParams: ISearchParams;
  searchItemSelected: any;
  filterValues: ISearchFilter;
  hiddenFilters: FilterControlNameEnum[];
  coordinatesRect: ICoordinatesRect | null;
  mapCenter: ILocation | null;
  zoom: number | null;
  propertyMap: IPropertyMapModel[];
  propertyList: IPropertyListModel[];
  sortOrder: PropertySortEnum;
  count: number;
  totalCount: number;
  isShowMap: boolean;
  isNarrowMode: boolean;
  pendingSaveSearch: boolean;
  waitingUpdateSavedSearch: number;
  pageNumber: number;
  pendingMap: boolean;
  pendingList: boolean;
}

export const defaultFilterValues = {
  [FilterNameEnum.priceFrom]: undefined,
  [FilterNameEnum.priceTo]: undefined,
  [FilterNameEnum.bedroomNumber]: PropertyBedNumberEnum.ANY,
  [FilterNameEnum.bedroomExact]: undefined,
  [FilterNameEnum.bathroomNumber]: PropertyBathNumberEnum.ANY,
  [FilterNameEnum.bathroomExact]: undefined,
  [FilterNameEnum.propertyType]: PropertyTypeEnum.SALE,
  [FilterNameEnum.homeType]:
    <PropertyHomeTypeEnum[]>Object.keys(PropertyHomeTypeEnum).map((k) => PropertyHomeTypeEnum[k]) ?? [],
  [FilterNameEnum.condition]: undefined,
  [FilterNameEnum.parkingNumber]: PropertyParkingNumberEnum.ANY,
  [FilterNameEnum.mustHaveGarage]: undefined,
  [FilterNameEnum.homeSizeFrom]: undefined,
  [FilterNameEnum.homeSizeTo]: undefined,
  [FilterNameEnum.lotSizeFrom]: undefined,
  [FilterNameEnum.lotSizeTo]: undefined,
  [FilterNameEnum.yearBuildFrom]: undefined,
  [FilterNameEnum.yearBuildTo]: undefined,
  [FilterNameEnum.hasAC]: false,
  [FilterNameEnum.hasBasement]: false,
};

export const initialSearchPageState: SearchPageState = {
  searchParams: null,
  searchItemSelected: null,
  filterValues: defaultFilterValues,
  hiddenFilters: [],
  coordinatesRect: null,
  mapCenter: null,
  zoom: null,
  propertyList: [],
  propertyMap: [],
  sortOrder: PropertySortEnum.Newest,
  count: 0,
  totalCount: 0,
  isShowMap: false,
  isNarrowMode: false,
  pendingSaveSearch: false,
  waitingUpdateSavedSearch: 0,
  pageNumber: 1,
  pendingMap: false,
  pendingList: false,
};

const isBrowser = typeof window !== 'undefined';

const getPrefilledInitialState = (): SearchPageState => {
  const lsValue = isBrowser ? JSON.parse(localStorage.getItem(LocalStorageEnum.SearchFilter)) : null;
  const filterValues = {
    ...defaultFilterValues,
    ...(lsValue ?? {}),
  };

  return {
    ...initialSearchPageState,
    filterValues,
  };
};

export const searchPageReducer = createReducer(
  getPrefilledInitialState(),

  on(SearchPageActions.initSavedParams, (state, action) => {
    const initParams: IInitSearchParams = JSON.parse(localStorage.getItem(LocalStorageEnum.InitSearchParams) ?? '{}');
    return {
      ...state,
      searchParams: initParams.searchParams ?? null,
      mapCenter: initParams.mapCenter ?? null,
      zoom: initParams.zoom ?? null,
    };
  }),

  on(SearchPageActions.performSearch, (state, action) => {
    return {
      ...state,
      pendingList: true,
    };
  }),

  on(SearchPageActions.filterChanged, (state, action: { data: { [key in FilterNameEnum]?: any } }) => {
    const newValue = { ...state.filterValues, ...action.data };

    if (isBrowser) {
      localStorage.setItem(LocalStorageEnum.SearchFilter, JSON.stringify(newValue));
    }

    return {
      ...state,
      filterValues: newValue,
      pageNumber: 1,
      pendingMap: state.isNarrowMode ? state.isShowMap : true,
      pendingList: state.isNarrowMode ? !state.isShowMap : true,
    };
  }),

  on(SearchPageActions.pageNumberChanged, (state, action: { pageNumber: number }) => {
    return {
      ...state,
      pageNumber: action.pageNumber,
      pendingList: true,
    };
  }),

  on(SearchPageActions.filterHidden, (state, action: { data: FilterControlNameEnum[] }) => {
    return {
      ...state,
      hiddenFilters: action.data,
    };
  }),

  on(SearchPageActions.changeCoordinatesRect, (state, action: { coordinatesRect: ICoordinatesRect }) => {
    return {
      ...state,
      coordinatesRect: action.coordinatesRect,
      pageNumber: 1,
      pendingMap: state.isNarrowMode ? state.isShowMap : true,
      pendingList: state.isNarrowMode ? !state.isShowMap : true,
    };
  }),

  on(SearchPageActions.clearPending, (state, _) => {
    return {
      ...state,
      pendingMap: false,
      pendingList: false,
    };
  }),

  on(SearchPageActions.clearSearchPage, (state, _) => {
    return {
      ...state,
      //coordinatesRect: null,
      propertyList: [],
      propertyMap: [],
      pageNumber: 1,
      pendingMap: false,
      pendingList: false,
    };
  }),

  on(SearchPageActions.sortOrderChanged, (state, action: { data: PropertySortEnum }) => {
    return {
      ...state,
      sortOrder: action.data ?? PropertySortEnum.Newest,
      pendingList: true,
    };
  }),

  on(SearchPageActions.propertyMapChanged, (state, action: IPropertySearchMapResponse) => {
    return {
      ...state,
      propertyMap: action.data ?? [],
      count: action.count ?? 0,
      totalCount: action.totalCount ?? 0,
      pendingMap: false,
    };
  }),

  on(SearchPageActions.propertyListChanged, (state, action: IPropertySearchListResponse) => {
    // Temporary search page uses fake photos.

    let data = mapperDemoPhotosUtils(action.data);

    return {
      ...state,
      propertyList: data ?? [],
      count: action.count ?? 0,
      totalCount: action.totalCount ?? 0,
      pendingList: false,
    };
  }),

  on(SearchPageActions.setShowMap, (state, action: { isShowMap: boolean }) => {
    return {
      ...state,
      isShowMap: action.isShowMap,
      propertyList: action.isShowMap ? [] : state.propertyList,
    };
  }),

  on(SearchPageActions.setNarrowMode, (state, action: { isNarrowMode: boolean }) => {
    return {
      ...state,
      isNarrowMode: action.isNarrowMode,
    };
  }),

  on(SearchPageActions.setSearchParams, (state, action: { searchParams: ISearchParams; item?: any; zoom?: number }) => {
    const initParams: IInitSearchParams = {
      searchParams: action.searchParams ?? undefined,
      searchItemSelected: action.item ?? undefined,
      zoom: action.zoom ?? undefined,
    };
    localStorage.setItem(LocalStorageEnum.InitSearchParams, JSON.stringify(initParams));

    return {
      ...state,
      searchParams: action.searchParams ?? null,
      searchItemSelected: action.item ?? null,
      mapCenter: null,
      coordinatesRect:
        state.isNarrowMode && !state.isShowMap && !!action.item
          ? calculateBoundingBox(+action.item.lat, +action.item.lon, getRadiusByMapZoom(state.zoom))
          : state.coordinatesRect,
    };
  }),

  on(SearchPageActions.clearFoundAddressItem, (state, action) => {
    return {
      ...state,
      searchItemSelected: null,
    };
  }),

  on(SearchPageActions.saveMapCenter, (state, action: { mapCenter: ILocation; zoom: number }) => {
    return {
      ...state,
      mapCenter: action.mapCenter ?? null,
      zoom: action.zoom ?? null,
    };
  }),

  on(SearchPageActions.resetAllFilters, (state) => {
    if (isBrowser) {
      localStorage.setItem(LocalStorageEnum.SearchFilter, '{}');
    }
    return {
      ...state,
      pageNumber: 1,
      filterValues: defaultFilterValues,
    };
  }),

  on(SearchPageActions.pendingSaveSearch, (state, action) => {
    return {
      ...state,
      pendingSaveSearch: action.data,
    };
  }),

  on(SearchPageActions.renewSearch, (state, action) => {
    return {
      ...state,
      sort: action.data.sort,
    };
  }),

  on(SearchPageActions.waitingSaveSearch, (state, action) => {
    return {
      ...state,
      waitingUpdateSavedSearch: action.data,
    };
  }),
);
