import { PropertyConditionEnum, PropertyHeatingTypeEnum, PropertyHomeTypeEnum, PropertyTypeEnum } from '@enums';
import { IAddressProperty, ILocation, IPropertyOwn } from '@interfaces';

export class YourHomeDetailsModel {
  id: number | null;
  address: IAddressProperty;
  addressWithoutNumber: boolean | null;
  bathroom: number;
  bedroom: number;
  brokerName: string | null;
  condition: PropertyConditionEnum;
  description: string;
  floor: number | null;
  fullAddress: string;
  hasGarage: boolean;
  hasImages: boolean;
  hasVideo: boolean;
  has3DTour: boolean;
  hasAC: boolean;
  homeSize: number;
  livingSize: number;
  isActiveStatus: boolean;
  location: ILocation;
  lotSize: number;
  price: number;
  priceCurrency: string;
  propertyType: PropertyTypeEnum;
  homeType: PropertyHomeTypeEnum;
  totalFloors: number;
  totalParking: number;
  yearBuild: number;

  images: string[];
  thumbImages: string[];

  petsAllowed: boolean;
  appliance: boolean;
  heating: PropertyHeatingTypeEnum;
  cardHomeType: string;

  constructor(raw: IPropertyOwn) {
    this.id = raw.id;
    this.address = raw.address;
    this.addressWithoutNumber = raw.addressWithoutNumber;
    this.bathroom = raw.bathroom === 0 ? null : raw.bathroom;
    this.bedroom = raw.bedroom === 0 ? null : raw.bedroom;
    this.brokerName = raw.brokerName;
    this.condition = raw.condition.toString() === '0' ? PropertyConditionEnum.FINISHED : raw.condition;
    this.description = raw.description.replace(/(\n\s*\n\s*){2,}/g, '\n\n').trim();
    this.floor = raw.floor === 0 ? null : raw.floor;
    this.totalFloors = raw.totalFloors === 0 ? null : raw.totalFloors;
    this.fullAddress = raw.fullAddress;
    this.hasGarage = raw.hasGarage;
    this.hasImages = raw.hasImages;
    this.hasVideo = raw.hasVideo;
    this.has3DTour = raw.has3DTour;
    this.hasAC = raw.hasAC;
    this.homeSize = raw.homeSize;
    this.livingSize = raw.homeSize;
    this.isActiveStatus = raw.isActiveStatus;
    this.location = raw.location;
    this.lotSize = raw.lotSize === 0 ? null : raw.lotSize;
    this.price = raw.price;
    this.priceCurrency = raw.priceCurrency;
    this.propertyType = raw.propertyType;
    this.homeType = raw.homeType;
    this.totalParking = raw.totalParking === 0 ? null : raw.totalParking;
    this.yearBuild = raw.yearBuild === 0 ? null : raw.yearBuild;
    this.images = raw.images;
    this.thumbImages = raw.thumbImages;
    this.petsAllowed = raw.petsAllowed;
    this.appliance = raw.appliance;
    this.heating = raw.heating;
    this.cardHomeType = raw.cardHomeType;
  }
}
