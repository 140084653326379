export enum PropertyConditionEnum {
  UNDER_CONSTRUCTION = '1',
  PREPARATION = '2',
  FINISHED = '3',
  NEW_BUILDING = '4', // New building = Novostavba
  PARTIAL = '5', // Partial reconstruction = Čiastočná rekonštrukcia
  COMPLETE = '6', // Complete reconstruction = Kompletná rekonštrukcia
  ORIGINAL = '7', // Original condition = Pôvodný stav
  DEVELOPMENT = '8', // Development project = Developerský projekt
}
