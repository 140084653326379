<section class="modal-cluster">
  <ng-container *ngIf="markers?.length">
    <div class="markers-address text-ellipsis" [title]="markers[0].fullAddress">
      {{ markers[0].fullAddress }}
    </div>
  </ng-container>
  <div class="items-container">
    <div class="item" *ngFor="let item of markers">
      <img imageError src="{{item.mainImage ?? ''}}" (click)="seeDetails($event, item)"/>
      <div class="object-item-info">
        <div class="price-and-options">
          <span class="price">
            {{ item.price | currency:  item.priceCurrency :true:'1.0-0' }}
          </span>
        </div>
        <div class="options-block">
          <p *ngIf="item.bedroom"><b>{{item.bedroom}}</b> <span>{{'cluster_property.beds' | translate}}</span></p>
          <p *ngIf="item.bathroom"><b>{{item.bathroom}}</b> <span>{{'cluster_property.bath' | translate}}</span></p>
          <p *ngIf="item.parking"><b>{{item.parking}}</b> <span>{{'cluster_property.parking' | translate}}</span></p>
        </div>
      </div>
    </div>
  </div>
</section>
