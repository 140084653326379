import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageEnum, ModalEnum } from '@enums';
import { select, Store } from '@ngrx/store';
import {
  injectDestroyService,
  ModalsService,
  provideDestroyService,
  SavedHomesService,
  SearchService,
  PropertyDetailsService,
  LastViewedPropertiesService,
} from '@service';
import { selectHeaderLogged, selectSavedHomesIds } from '@store';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { IPropertyDetailsModel, IApiDataResponse } from '@interfaces';
import { mapperDemoPhotosUtilsSingle } from '../../helpers/mapper-demo-photos.utils';
import { LocalStorageService } from '../../services/local-storage.service';
import * as SavedHomesActions from '@store';
import { getImageUrlDemo, getImageUrlOriginal, getImageUrlThumb, loadSavedHomes } from '@helpers';
import { take } from 'rxjs';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
  providers: [provideDestroyService()],
})
export class PropertyDetailsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = injectDestroyService();

  public propertyId: string;
  public property: IPropertyDetailsModel;
  public propertySimilar: IPropertyDetailsModel[] = [];
  public isInOneColum: boolean = false;
  public isLoggedUser: boolean = false;
  public thumbImages = [];
  public originalImages = [];

  private hiddenProperties: number[] = this.ls.getItem(LocalStorageEnum.HIDDEN_PROPERTIES);
  protected readonly ModalEnum = ModalEnum;

  @HostListener('window:resize')
  windowResize() {
    this.isInOneColum = window.innerWidth < 769;
  }

  constructor(
    private router: Router,
    private propertyDetails: PropertyDetailsService,
    private searchService: SearchService,
    private savedHomesService: SavedHomesService,
    private lastViewedPropertiesService: LastViewedPropertiesService,
    private modalsService: ModalsService,
    private store: Store,
    private ls: LocalStorageService,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectHeaderLogged), takeUntil(this.destroy$), delay(100)).subscribe((res) => {
      this.isLoggedUser = res;

      if (this.isLoggedUser) {
        loadSavedHomes(this.savedHomesService, this.searchService, this.destroy$, this.store);
        this.lastViewedPropertiesService.addLastViewed([+this.propertyId]).pipe(take(1)).subscribe();
      }
    });

    this.getPropertyId();
    this.getProperty();
    this.windowResize();

    this.store.pipe(select(selectSavedHomesIds), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.includes(+this.propertyId)) {
        this.property = { ...this.property, isSavedHome: true };
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((res) => {
        if (res['url'] && res['url'].includes('property') && res['url'].split('/')[2] !== this.propertyId) {
          this.getPropertyId();
          this.getProperty();
          // this.loadSimilarProperties();
        }
      });
  }

  ngOnDestroy() {}

  public getPropertyId(): void {
    this.propertyId = this.router.url.split('/')[2];
    this.loadSimilarProperties();
  }

  public getProperty(): void {
    this.propertyDetails
      .getProperty(this.propertyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((property: IApiDataResponse<IPropertyDetailsModel>) => {
        this.property = { ...this.property, ...property.data };
        this.property = mapperDemoPhotosUtilsSingle(this.property);
        this.searchService.setPropertyCardInfoLine(this.property);

        this.thumbImages = this.property.images?.length
          ? this.property.images.map((image: string) =>
              this.property.isDemoPhoto ? getImageUrlDemo(image) : getImageUrlThumb(image),
            )
          : [];
        this.originalImages = this.property.images?.length
          ? this.property.images.map((image: string) =>
              this.property.isDemoPhoto ? getImageUrlDemo(image) : getImageUrlOriginal(image),
            )
          : [];

        this.searchService.setPropertyCombinedDetails(this.property);

        // adding one copy of existing image to do a blured 3d tour invite
        if (this.property.has3DTour && this.property.images?.length) {
          this.property.images.push(this.property.images[0]);
        }

        if (this.hiddenProperties.includes(+this.propertyId)) {
          this.property = { ...this.property, isHidden: true };
        }
      });
  }

  public loadSimilarProperties(): void {
    this.propertyDetails
      .getSimilarProperties(this.propertyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.propertySimilar = res?.data ?? []));
  }

  public openModal(modalId: ModalEnum): void {
    this.modalsService.open(modalId);
  }

  public saveHandler(state: boolean): void {
    if (state) {
      this.savedHomesService
        .add(this.property.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((_) => {
          this.property = { ...this.property, isSavedHome: true };
          this.store.dispatch(SavedHomesActions.addSavedHomes({ data: { ...this.property, isSavedHome: true } }));
        });
      return;
    }

    this.savedHomesService
      .delete(this.property.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        this.property = { ...this.property, isSavedHome: false };
        this.store.dispatch(SavedHomesActions.removeSavedHomes({ data: this.property }));
      });
  }

  public hideHandler(isHidden: boolean): void {
    this.property = { ...this.property, isHidden };
    this.ls.updateItemsArray(LocalStorageEnum.HIDDEN_PROPERTIES, +this.propertyId, !isHidden);
  }

  public back() {
    this.router.navigate(['/search']);
  }

  public changePropertyHandler($event: any) {
    const headerElement = document.querySelector('#headerPropertyDetails');

    if (headerElement) {
      headerElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (this.propertyId === $event) {
      return;
    }

    this.propertyId = $event;
    this.getProperty();
    this.loadSimilarProperties();
  }
}
