import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBlurOnEnter]',
})
export class BlurOnEnterDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('document:keydown.enter') onClick() {
    this.elementRef.nativeElement.blur();
  }
}
