import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() ch: any = false;
  @Input() id: string;
  @Input() disabled: any = false;
  @Input() mock: boolean = false;
  onChange: Function;
  onTouched: Function;
  private inputValue: string;

  get value() {
    return this.inputValue;
  }

  set value(value: string) {
    this.inputValue = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  click() {
    if (this.mock) {
      this.ch = false;
      return;
    }
    if (this.disabled) {
      return;
    }
    this.toggle();
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(this.ch);
    }
  }

  toggle() {
    this.ch = !this.ch;
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {
    this.value = obj;
  }
}
