<ng-container *ngIf="isSimpleList; else notSimpleList">
  <div class="select-items">
    <div class="select-item" *ngFor="let simpleItem of objectList" id="{{ simpleItem }}">
      {{ simpleItem | translate }}
    </div>
  </div>
</ng-container>

<ng-template #notSimpleList>
  <div class="select-items">
    <div class="select-item" *ngFor="let item of objectList" id="{{ item | json }}">
      {{ item[setStructureList.nameFieldForView] | translate }}
    </div>
  </div>
</ng-template>
