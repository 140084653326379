import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-token-health',
  templateUrl: './token-health.component.html',
  styleUrls: ['./token-health.component.scss'],
})
export class TokenHealthComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
