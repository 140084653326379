import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DaysOnMarketFormatModule, SliderModule } from '../../component';
import { PropertyDefaultCardComponent } from './property-default-card.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PropertyDefaultCardComponent],
  imports: [CommonModule, SliderModule, DaysOnMarketFormatModule, TranslateModule, NgbTooltip],
  exports: [PropertyDefaultCardComponent],
})
export class PropertyDefaultCardModule {}
