import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LAST_VIEWED, LAST_VIEWS_PROPERTIES } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class LastViewedPropertiesService {
  constructor(private http: HttpClient) {}

  public getProperties(ids: number[]) {
    return this.http.post(LAST_VIEWS_PROPERTIES, { ids });
  }

  public getLastViewed(limit = 10, page = 1) {
    return this.http.post(LAST_VIEWED, { limit, page });
  }

  public addLastViewed(property_ids: number[]) {
    return this.http.put(LAST_VIEWED, { property_ids });
  }

  public deleteLastViewed(property_id: number) {
    return this.http.delete(LAST_VIEWED, { body: { property_id } });
  }
}
