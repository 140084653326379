import { IPropertyListModel } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const clearSavedHomes = createAction('[SAVED HOMES] CLEAR SAVED HOMES');

export const loadSavedHomes = createAction('[SAVED HOMES] LOAD', props<{ data: IPropertyListModel[] }>());

export const addSavedHomes = createAction('[SAVED HOMES] ADD', props<{ data: IPropertyListModel }>());

export const removeSavedHomes = createAction('[SAVED HOMES] REMOVE', props<{ data: IPropertyListModel }>());
