import { environment } from '@env';
import { ImageTypeEnum } from '@enums';

export function getImageUrlThumb(imageId) {
  return `${environment.imgBucketUrl}${imageId}.${ImageTypeEnum.thumb}`;
}

export function getImageUrlOriginal(imageId) {
  return `${environment.imgBucketUrl}${imageId}.${ImageTypeEnum.origin}`;
}

export function getImageUrlDemo(imageId) {
  return `${environment.imgBucketUrl}${imageId}`;
}
