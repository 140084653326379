<div
  class="my-select-container"
  (click)="onContainerClick($event)"
  (clickOutside)="closeOptionsList()"
>
  <span
    class="select-icon"
    [class.is-open]="showOptions"
    (click)="onContainerClick($event)"
  >
  </span>
  <div *ngIf="showOptions"
       class="options-list"
       #optionsList
  >
    <div
      *ngFor="let option of options"
      class="option-item text-ellipsis"
      (click)="onSelectionChange($event, option)"
    >
      {{option.value?.length ? prefix : ''}}{{ option.name }}
    </div>
  </div>
  <input
    #input
    [placeholder]="placeholder"
    [(ngModel)]="customValue"
    (blur)="onCustomValueBlur()"
    (input)="onCustomValueInput($event)"
  >
</div>
