import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiDataResponse } from '@interfaces';
import { IPopularRegion } from '../interfaces/popular-region.interface';

@Injectable({
  providedIn: 'root',
})
export class PopularRegionsService {
  constructor(private readonly http: HttpClient) {}

  loadPopularRegions(): Observable<IApiDataResponse<IPopularRegion[]>> {
    return this.http.get<IApiDataResponse<IPopularRegion[]>>('/popular-regions');
  }

  loadPopularRegionsMock(): Observable<IApiDataResponse<IPopularRegion[]>> {
    const regions: IPopularRegion[] = [
      { code: 'BA', region: 'Bratislava' },
      { code: 'BB', region: 'Banská Bystrica' },
      { code: 'KE', region: 'Košice' },
      { code: 'MT', region: 'Martin' },
      { code: 'NT', region: 'Nitra' },
      { code: 'PP', region: 'Poprad' },
      { code: 'PO', region: 'Prešov' },
      { code: 'PD', region: 'Prievidza' },
      { code: 'RK', region: 'Ružomberok' },
      { code: 'TN', region: 'Trenčín' },
      { code: 'TT', region: 'Trnava' },
      { code: 'ZA', region: 'Žilina' },
    ];

    return new Observable((observer) => {
      observer.next({ data: regions });
    });
  }
}
