import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { PipesModule } from '../../pipes/pipes.module';
import { RangeSelectComponent } from './range-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomElementsFormModule } from '../../custom-elements-form.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RangeSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CustomElementsFormModule,
    TranslateModule,
    SliderModule,
    InputTextModule,
    PipesModule,
  ],
  exports: [RangeSelectComponent],
})
export class RangeSelectModule {}
