import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthService } from '@service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { REGISTER, SIGN_IN, SIGN_OUT, SIGN_OUT_ALL } from '../shared/endpoints';

@Injectable()
export class DeviceIdInterceptors implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let data;

    const urls = [SIGN_IN, REGISTER, SIGN_OUT, SIGN_OUT_ALL];

    if (isPlatformBrowser(this.platformId)) {
      if (urls.includes(request.url)) {
        data = { headers: request.headers.set('X-Device-Id', this.authService.getClientId()) };
      }
    }

    const clonedRequest = request.clone(data);
    return next.handle(clonedRequest).pipe(
      catchError((error: any) => {
        of(error).subscribe((response) => {});

        return throwError(error);
      }),
    );
  }
}
