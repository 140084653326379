export enum FilterNameEnum {
  propertyType = 'propertyType',
  homeType = 'homeType',
  condition = 'condition',
  priceFrom = 'priceFrom',
  priceTo = 'priceTo',
  homeSizeFrom = 'homeSizeFrom',
  homeSizeTo = 'homeSizeTo',
  lotSizeFrom = 'lotSizeFrom',
  lotSizeTo = 'lotSizeTo',
  yearBuildFrom = 'yearBuildFrom',
  yearBuildTo = 'yearBuildTo',
  parkingNumber = 'parkingNumber',
  mustHaveGarage = 'mustHaveGarage',
  bedroomNumber = 'bedroom',
  bedroomExact = 'bedroomExact',
  bathroomNumber = 'bathroom',
  bathroomExact = 'bathroomExact',
  country = 'country',
  city = 'city',
  hasAC = 'hasAC',
  hasBasement = 'hasBasement',
}
