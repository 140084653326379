@if (previewMode) {
  <div *ngFor="let img of previewFiles; let index = index;" class="image-holder">
    <span *ngIf="index === 0">{{'image_list.main_photo' | translate}}</span>
    <img [src]="img.preview" draggable="false"/>
    <button class="img-button delete" title="{{'image_list.delete' | translate}}" (click)="deleteImage(index)"></button>
    <button *ngIf="index !== 0" class="img-button main-image" title="{{'image_list.make_main' | translate}}" (click)="onMakeMainImage(index)"></button>
  </div>
} @else {
  <div *ngFor="let img of images; let index = index" class="image-holder" [class.opacity-50]="pending">
    <span *ngIf="index === 0">{{'image_list.main_photo' | translate}}</span>
    <img imageError src="{{img}}" draggable="false"/>
    <button class="img-button delete" title="{{'image_list.delete' | translate}}" (click)="deleteImage(index)"></button>
    <button *ngIf="index !== 0" class="img-button main-image" title="{{'image_list.make_main' | translate}}" (click)="onMakeMainImage(index)"></button>
  </div>
}

<div *ngIf="pending" class="preloader">
  <div class="sp sp-circle"></div>
</div>
