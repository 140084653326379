import { Component, Input } from '@angular/core';
import { BRAND_APP } from 'app/shared/helpers';

@Component({
  selector: 'app-days-on-market-format',
  templateUrl: './days-on-market-format.component.html',
  styleUrls: ['./days-on-market-format.component.scss'],
})
export class DaysOnMarketFormatComponent {
  @Input() daysOnEstate: any;
  brandApp = BRAND_APP;
}
