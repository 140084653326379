export function compareObjects(obj1: any, obj2: any): boolean {
  if (!obj1 || !obj2) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      return false;
    }
  }

  return true;
}
