import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortOrderComponent } from './sort-order.component';
import { DirectivesModule } from '../../../shared/directives';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SortOrderComponent],
  imports: [CommonModule, DirectivesModule, TranslateModule],
  exports: [SortOrderComponent],
})
export class SortOrderModule {}
