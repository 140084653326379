import { Map, Marker } from 'leaflet';
import { isPlatformBrowser } from '@angular/common';

declare let require: any;

export class BaseMapService {
  public L = null;
  public M = null;
  public map: Map;
  public padValue = -0.08;
  public tileLayer: any;
  public maxBounds: any;
  public marker: Marker; // standalone marker
  public svg =
    '<svg width="800px" height="800px" viewBox="-4 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">  <g id="SVGRepo_bgCarrier" stroke-width="0"/>  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>  <g id="SVGRepo_iconCarrier"><title>map-marker</title> <desc>Created with Sketch.</desc> <defs> </defs> <g id="Vivid.JS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)"> <g id="Icons" transform="translate(37.000000, 169.000000)"> <g id="map-marker" transform="translate(78.000000, 468.000000)"> <g transform="translate(10.000000, 6.000000)"> <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#A91712"> </path> <circle id="Oval" fill="#fff" fill-rule="nonzero" cx="14" cy="14" r="7"> </circle> </g> </g> </g> </g> </g> </g>  </svg>';
  public readonly defaultIcon = 'data:image/svg+xml;base64,' + btoa(this.svg);

  constructor(platformId: Object) {
    if (isPlatformBrowser(platformId)) {
      this.L = require('leaflet');
      this.M = require('@asymmetrik/ngx-leaflet-markercluster');
    }
  }

  public flyTo(lat: number, lon: number, zoom?: number): void {
    this.map.flyTo([lat, lon], zoom, { animate: false, duration: 0, easeLinearity: 10 });
  }

  public addMarker(lat: number, lon: number): Marker {
    const icon = this.L.icon({
      iconUrl: this.defaultIcon,
      iconSize: [36, 36],
      iconAnchor: [15, 30],
      className: 'separate-marker',
    });
    return new this.L.marker([lat, lon], { icon }).addTo(this.map);
  }

  public removeMarker(marker: Marker): void {
    if (!marker) {
      return;
    }
    this.map.removeLayer(marker);
  }
}
