import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailFrequencyEnum } from '@enums';
import { ISaveSearches, IApiDataResponse } from '@interfaces';
import { LAST_SEARCH } from 'app/shared/helpers';
import { Observable } from 'rxjs';
import { SAVED_SEARCHES } from '../endpoints';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SavedSearchesService {
  constructor(
    private http: HttpClient,
    private ls: LocalStorageService,
  ) {}

  public loadData(): Observable<IApiDataResponse<ISaveSearches[]>> {
    return this.http.get<IApiDataResponse<ISaveSearches[]>>(SAVED_SEARCHES);
  }

  public save(body: ISaveSearches) {
    const name = `${new Date().toLocaleString()}`;

    return this.http.post(SAVED_SEARCHES, { ...body, name });
  }

  public update(id, data: { emailFrequency?: EmailFrequencyEnum; name?: string; subscribed?: boolean }) {
    return this.http.put(`${SAVED_SEARCHES}/${id}`, data);
  }

  public delete(id) {
    return this.http.delete(`${SAVED_SEARCHES}/${id}`);
  }

  public loadLastMapSettingToLocalStorage() {
    return this.ls.getItem(LAST_SEARCH.MAP);
  }
}
