import { AppState, defaultFilterValues, SearchPageState } from '@store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilterControlNameEnum, FilterNameEnum } from '@enums';

export const stateMap = (state: AppState) => state.map;
export const mapData = createSelector(stateMap, (prop) => prop);

export const stateFilters = (state: AppState) => state.filters;
export const filtersData = createSelector(stateFilters, (prop) => {
  if (prop) {
    return prop;
  }
  return {};
});

//-----------------------------------------------------------------------

export const selectSearchPageState = createFeatureSelector<SearchPageState>('searchPage');

export const selectPendingList = createSelector(selectSearchPageState, (state) => state.pendingList);

export const selectPendingMap = createSelector(selectSearchPageState, (state) => state.pendingMap);

export const selectSearchParams = createSelector(selectSearchPageState, (state) => state.searchParams);

export const selectSearchItemSelected = createSelector(selectSearchPageState, (state) => state.searchItemSelected);

export const selectMapCenter = createSelector(selectSearchPageState, (state) => state.mapCenter);

export const selectMapZoom = createSelector(selectSearchPageState, (state) => state.zoom);

export const selectFilterValues = createSelector(selectSearchPageState, (state) => state.filterValues);

export const selectFiltersHidden = createSelector(selectSearchPageState, (state) => state.hiddenFilters);

export const selectCoordinatesRect = createSelector(selectSearchPageState, (state) => state.coordinatesRect);

export const selectSortOrder = createSelector(selectSearchPageState, (state) => state.sortOrder);

export const selectPropertiesList = createSelector(selectSearchPageState, (state) => state.propertyList);

export const selectPropertiesMap = createSelector(selectSearchPageState, (state) => state.propertyMap);

export const selectPropertiesListCount = createSelector(selectSearchPageState, (state) => state.totalCount);

export const selectIsShowMap = createSelector(selectSearchPageState, (state) => state.isShowMap);

export const selectIsNarrowMode = createSelector(selectSearchPageState, (state) => state.isNarrowMode);

export const selectPageNumber = createSelector(selectSearchPageState, (state) => state.pageNumber);

export const selectFilterControlDirty = (props: { ctrlName: FilterControlNameEnum }) =>
  createSelector(selectSearchPageState, (state) => {
    if (!props?.ctrlName || !defaultFilterValues) return 0;
    const checkIsDirty = (fields: FilterNameEnum[]): boolean => {
      return fields.some(
        (f) =>
          state.filterValues[f] !== defaultFilterValues[f] && (!!state.filterValues[f] || !!defaultFilterValues[f]),
      );
    };

    const priceDirty = checkIsDirty([FilterNameEnum.priceFrom, FilterNameEnum.priceTo]);
    const homeTypeDirty =
      JSON.stringify(state.filterValues[FilterNameEnum.homeType]) !==
      JSON.stringify(defaultFilterValues[FilterNameEnum.homeType]);
    const bedsDirty = checkIsDirty([
      FilterNameEnum.bedroomNumber,
      FilterNameEnum.bedroomExact,
      FilterNameEnum.bathroomNumber,
      FilterNameEnum.bathroomExact,
    ]);
    const parkingDirty = checkIsDirty([FilterNameEnum.parkingNumber, FilterNameEnum.mustHaveGarage]);
    const homeSizeDirty = checkIsDirty([FilterNameEnum.homeSizeFrom, FilterNameEnum.homeSizeTo]);
    const lotSizeDirty = checkIsDirty([FilterNameEnum.lotSizeFrom, FilterNameEnum.lotSizeTo]);
    const yearBuildDirty = checkIsDirty([FilterNameEnum.yearBuildFrom, FilterNameEnum.yearBuildTo]);
    const conditionDirty =
      JSON.stringify(state.filterValues[FilterNameEnum.condition]) !==
      JSON.stringify(defaultFilterValues[FilterNameEnum.condition]);
    const otherDirty = checkIsDirty([FilterNameEnum.hasAC, FilterNameEnum.hasBasement]);
    const moreDirty = [
      state.hiddenFilters.includes(FilterControlNameEnum.price) && priceDirty,
      state.hiddenFilters.includes(FilterControlNameEnum.homeType) && homeTypeDirty,
      state.hiddenFilters.includes(FilterControlNameEnum.beds) && bedsDirty,
      parkingDirty,
      homeSizeDirty,
      lotSizeDirty,
      yearBuildDirty,
      conditionDirty,
      otherDirty,
    ];

    switch (props.ctrlName) {
      case FilterControlNameEnum.price:
        return priceDirty ? 1 : 0;
      case FilterControlNameEnum.homeType:
        return homeTypeDirty ? 1 : 0;
      case FilterControlNameEnum.beds:
        return bedsDirty ? 1 : 0;
      case FilterControlNameEnum.more:
        return moreDirty.filter((d) => !!d).length;
      default:
        return 0;
    }
  });

export const pendingSavedSearch = createSelector(selectSearchPageState, (state) => state.pendingSaveSearch);
export const waitingUpdateSavedSearch = createSelector(
  selectSearchPageState,
  (state) => state.waitingUpdateSavedSearch,
);
